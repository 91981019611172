import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';

@Component({
  selector: 'mpms-change-lead-owner',
  templateUrl: './change-lead-owner.component.html',
  styleUrls: ['./change-lead-owner.component.scss']
})
export class ChangeLeadOwnerComponent implements OnInit {
  leadOwnerForm: FormGroup;
  leadOwners = [];
  selectedOwner: number;
  leadData: any;

  constructor(private fb: FormBuilder,
              public readonly dialogRef: MatDialogRef<ChangeLeadOwnerComponent>,
              public readonly scheduleService: SurgeryScheduleService
    ) { }

  ngOnInit(): void {
      this.scheduleService.getLeadOwner().subscribe(owners => {
      this.leadOwners = owners;
      this.initForm(this.leadData);
    });
  }

  initForm(schedule): void {
    this.leadOwnerForm = this.fb.group({
      event: ['LeadOwner'],
      lead: [this.leadData?.lsLead],
      owner: [schedule.ownerName ?  schedule.ownerName : ''],
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY))
        .adminUser.email],

    });
  }

  saveLeadOwner(): void {
    this.scheduleService.saveSupervisor(this.leadOwnerForm.getRawValue()).subscribe(resp => {
      if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
        Swal.fire('Success', 'Changed Lead Owner successfully', 'success');
        setTimeout(()=>{
          location.reload();
        },1000)
        this.dialogRef.close()
      }
    });
  }

  onSelectOwner(name): void {
    console.log(name)
    this.leadOwnerForm.patchValue({
      owner: name
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
