
import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { PutAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';
import { PostAPICommand } from 'src/app/core/api/commands';

export class SaveLeadSourceCommand<T> extends PostAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead-source-admin-update`);
    }
}
