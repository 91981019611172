import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { City } from '../models/city.model';



export class CityAdapter implements IAdapter<City> {
    adaptToModel(resp: any): any {
        resp = resp.data;
        const response = [];
        
        if(resp?.length>0){
        for (let i = 0; i < resp.length; i++) {
            // const obj = {
            //     capitalId: resp[i].id,
            //     capitalName: resp[i].name,
            //     id: resp[i].id,
            //     name: resp[i].name
            // }
            // response.push(obj);
            for (let j = 0; j < resp[i].cities.length; j++) {
                let obj2 = {
                    capitalId: resp[i].id,
                    capitalName: resp[i].name,
                    id: resp[i].cities[j].id,
                    name: resp[i].cities[j].name
                }
                response.push(obj2);
            }
        }
    }
        return response;
    }
    adaptFromModel(data: City): City {
        return data;
    }
}
