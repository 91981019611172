export class User {
    name: string;
    constructor(data: User) {
        this.name = data?.name;
    }
}


export class Menu {
    adminMenuItems: Menu[];
    childMenuId: string;
    childMenuName: string;
    domains: string[];
    parentMenuId: string;
    parentMenuName: string;
    permissionId: string;
    url: string;
    constructor(data: Menu) {
        this.adminMenuItems = data?.adminMenuItems;
        this.childMenuId = data?.childMenuId;
        this.domains = data?.domains;
        this.parentMenuId = data?.parentMenuId;
        this.parentMenuName = data?.parentMenuName;
        this.permissionId = data?.permissionId;
        this.url = data?.url;
    }

}
