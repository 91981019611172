<div class="filter-container">
    <div class="fromData">
        <mat-form-field appearance="fill">
            <mat-label>From Date</mat-label>
            <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]="fromDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
    </div>
    <div class="toData">
        <mat-form-field appearance="fill">
            <mat-label>To Date</mat-label>
            <input matInput [matDatepicker]="pickerTo" (click)="pickerTo.open()" [(ngModel)]="toDate">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="apply">
        <button mat-raised-button color="primary"  (click)="onApplyFilter()">
            <span>Apply</span>
        </button>
    </div>
</div>