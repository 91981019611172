<mat-card>
  <mat-card-header>
    <mat-card-title>Supervisor List</mat-card-title>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content class="padding-4 customMatFields">
    <form [formGroup]="superVisorForm" *ngIf="superVisorForm">
      <div class="row">
        <div class="col-md-4">
          <mat-label>Supervisor List</mat-label>
          <mpms-autocomplete-dropdown [data]="supervisors" [bindLabel]="'name'" bindValue="userId"
            [selectedItem]="+superVisorForm.get('supervisor').value" (onOptionChange)="onSelectSupervisor($event)">
          </mpms-autocomplete-dropdown>
        </div>
        <div class="col-md-4">
          <mat-label>Presence Type</mat-label>
          <mat-select id="presenceType" name="presenceType" class="form-control" formControlName="presenceType">
            <mat-option value="">Please Select</mat-option>
            <mat-option value="Remote">Remote</mat-option>
            <mat-option value="In Person">In Person</mat-option> 
          </mat-select>
          <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="superVisorForm.get('presenceType')">
          </mpms-input-validation>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button class="mr-20" (click)="onCloseDialog()"> Close </button>
    <button mat-raised-button (click)="saveSuperVisor()" color="primary">Add </button>
  </mat-card-actions>
</mat-card>