import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mpms-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteDropdownComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input()groupBy: string;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() placeholder: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onOptionChange = new EventEmitter();
  @Input() selectedItem: string | number;



  constructor() {
  }

  onChangeOptions(): void {
    this.onOptionChange.emit(this.selectedItem);
  }

  ngOnChanges(): void {
    console.log(typeof(this.selectedItem));
  }

  ngOnInit(): void {
  }
}
