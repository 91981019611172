import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class AttributeHospital<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, city: string , service) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/location/hospital/doctor/-1?source=surgery&hospitalType=1&city=${city}&service=${service}`);
    }
}
