import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { CityFacadeService } from '../../facades/city-facade.service';
import { City } from '../../models/city.model';

@Component({
  selector: 'mpms-city-dropdown',
  templateUrl: './city-dropdown.component.html',
  styleUrls: ['./city-dropdown.component.scss']
})
export class CityDropdownComponent implements OnInit {
  cities: City[];
  @Output() emitSelectedCity = new EventEmitter();
  @Input() selectedCity: number;

  constructor(
    private cityService: CityFacadeService
  ) { }

  ngOnInit(): void {
    this.getCities();

  }

  getCities(): void {
    const store = localStorage.getItem(AppConstants.LS_USER_KEY);

    const user = store ? JSON.parse(store)?.adminUser : [];
    const email = user?.email;
    this.cityService.getCities(email).subscribe(cities => {
      this.cities = cities;
    });
  }

  getSelectedCity(city: any) {
    this.emitSelectedCity.emit(city);
  }

}
