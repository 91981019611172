import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';

import {  ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'mpms-associate-tags-lead',
  templateUrl: './associate-tags-lead.component.html',
  styleUrls: ['./associate-tags-lead.component.scss']
})
export class AssociateTagsLeadComponent implements OnInit {
  associateTagsForm: FormGroup;
  leadOwners = [];
  leadId: any;
  masterTags: Array<any>;;
  associatedTags: any;
  selectedOwner: number;
  leadData: any;
  tagsDefault:any;

  constructor(private fb: FormBuilder,
              public readonly dialogRef: MatDialogRef<AssociateTagsLeadComponent>,
              public readonly scheduleService: SurgeryScheduleService
    ) { }

  ngOnInit(): void {
    this.tagsDefault=this.associatedTags.map(tag=>tag.tagId)
      this.scheduleService.getMasterTags().subscribe(tags => {
      this.masterTags = tags;
    
      this.data=this.masterTags;
   
      this.initForm();
    });

   
  setTimeout(()=>{
    this.filteredOptions = this.searchTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      );
    },1000)
    this.tags.patchValue(this.tagsDefault)
  }
 

  initForm(): void {
    this.associateTagsForm = new FormGroup({
      leadId: new FormControl(this.leadId),
      postedBy: new FormControl(JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY))
        .adminUser.email),
      tags:new FormControl(this.associatedTags.map(tag=>tag.tagId)) 
    });
  }

  changeTags(e): void {
    this.associateTagsForm.patchValue({tags:e.value}, {
      onlySelf: true,
    });
  }
  saveLeadOwner(): void {

    console.log(this.associateTagsForm.getRawValue())
    this.scheduleService.saveTags(this.associateTagsForm.getRawValue()).subscribe(resp => {
      if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
        Swal.fire('Success', 'Tags updated succesfully', 'success');
        this.dialogRef.close();
        location.reload();
      }
      else
      {
        Swal.fire('Error', 'Error', 'error');
      }
    });
  }

  onSelectOwner(name): void {
    console.log(name)
    this.associateTagsForm.patchValue({
      owner: name
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
       



      
  @ViewChild('search') searchTextBox: ElementRef;

  tags = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues = [];
  data:any;

  filteredOptions: Observable<any[]>;

 

  /**
   * Used to filter data based on search input 
   */
  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValues();
    // this.tags.patchValue(this.selectedValues);
    this.associateTagsForm.patchValue({tags:this.selectedValues}, {
      onlySelf: true,
    });
    let filteredList = this.data.filter(option => option.tagName.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

/**
 * Remove from selected values based on uncheck
 */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value 
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state 
   */
  setSelectedValues() {
   
    console.log('tags', this.tags.value);
    if (this.tags.value && this.tags.value.length > 0) {
      this.tags.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }


}
