import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';

@Component({
  selector: 'mpms-update-referral-code',
  templateUrl: './update-referral-code.component.html',
  styleUrls: ['./update-referral-code.component.scss']
})
export class UpdateReferralCodeComponent implements OnInit {
  updateReferralForm: FormGroup;
  leadOwners = [];
  selectedOwner: number;
  leadData: any;

  constructor(private fb: FormBuilder,
              public readonly dialogRef: MatDialogRef<UpdateReferralCodeComponent>,
              public readonly scheduleService: SurgeryScheduleService
    ) { }

  ngOnInit(): void {
      this.scheduleService.getLeadOwner().subscribe(owners => {
      this.leadOwners = owners;
      console.log(this.leadData)
      this.initForm(this.leadData);
    });
  }

  initForm(schedule): void {
    this.updateReferralForm = this.fb.group({
      // event: ['update-referral-code'],
      lead: [schedule.lsLead],
      referralCode:[schedule.referralCode], 
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY))
        .adminUser.email],

    });
  }

  saveLeadOwner(): void {
    console.log(JSON.stringify(this.updateReferralForm.getRawValue()))
    this.scheduleService.saveReferralCode(this.updateReferralForm.getRawValue()).subscribe(resp => {
      if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
        Swal.fire('Success', 'Changed Referral Code successfully', 'success');
        setTimeout(()=>{
          location.reload();
        },1000)
        this.dialogRef.close()
      }
    });
  }

  onSelectOwner(name): void {
    console.log(name)
    this.updateReferralForm.patchValue({
      owner: name
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }


}
