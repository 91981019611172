import { environment } from 'src/environments/environment';
import { IAdapter } from '../../api/adapters/i-adapter';
import { ApiService } from '../../api/api.service';
import { GetAPICommand } from '../../api/commands';



export class GetUserCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, adapter: IAdapter<T>, token) {
        super(apiService, adapter, `${environment.apiEndpoint}/user/user/${token}/token`);
    }
}
