import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';
import { LeadDetailFacadeService } from 'src/app/main/lead/facades/lead-detail-facade.service';

@Component({
  selector: 'mpms-update-lead-source',
  templateUrl: './update-lead-source.component.html',
  styleUrls: ['./update-lead-source.component.scss']
})
export class UpdateLeadSourceComponent implements OnInit {
  updateSourceForm: FormGroup;
  leadOwners = [];
  selectedOwner: number;
  leadData: any;

  leadName: string;
  altMobile: string;
  serviceId: number;
  leadId: string;
  source: string;
  referrals = [];
  patientGender: string;
  patientAge: string;
  patientRelationship: string;
  patientLanguage: string;

  patientArea: string;
  relationFlag: boolean = false;
  languageFlag: boolean = false;
  relationship: any;
  sourceData: any;
  groupArr: any;
  isShowErrorMsg = false;
  showReferrerAgent: boolean = true;
  relationList = [];
  ownerName = [];
  createLeadLanguageList = ["English", "Hindi", "Marathi", "Telugu", "Tamil", "Kananda", "Others"];

  constructor(private fb: FormBuilder,
              public readonly dialogRef: MatDialogRef<UpdateLeadSourceComponent>,
              public readonly scheduleService: SurgeryScheduleService,
              private readonly leadDetailService: LeadDetailFacadeService) { }

  ngOnInit(): void {
    // Initialize form first
    this.initForm({ lsLead: '', source: '' });

    // Fetch lead owners and initialize form with actual data
    this.scheduleService.getLeadOwner().subscribe(owners => {
      this.leadOwners = owners;
      console.log(this.leadData);
      if (this.leadData) {
        this.initForm(this.leadData);
      }
    });

    // Fetch source names and group them
    this.leadDetailService.getSourceName().subscribe(res => {
      console.log(res);
      this.sourceData = res.data;
      this.groupArr = this.sourceData.reduce((r, { parentSource }) => {
        if (!r.some(o => o.parentSource === parentSource)) {
          r.push({ parentSource, groupItem: this.sourceData.filter(v => v.parentSource === parentSource) });
        }
        return r;
      }, []);
    });

    // Subscribe to form control value changes after form initialization
    this.updateSourceForm.get('source').valueChanges.subscribe(val => {
      this.getSource(val);
    });
  }

  getSource(val: string): void {
    console.log(val);
    if (val.includes("B2B")) {
      this.showReferrerAgent = false;
    } else {
      this.showReferrerAgent = true;
    }
    this.updateSourceForm.patchValue({ source: val });
  }

  initForm(schedule): void {
    this.updateSourceForm = this.fb.group({
      lead: [schedule.lsLead],
      comments: ['', [Validators.required]],
      source: [schedule.source],
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY)).adminUser.email]
    });
  }

  saveLeadOwner(): void {
    if (this.updateSourceForm.invalid) {
      this.isShowErrorMsg = true;
      return;
    }

    console.log(JSON.stringify(this.updateSourceForm.getRawValue()));
    this.scheduleService.SaveLeadSourceCommand(this.updateSourceForm.getRawValue()).subscribe(resp => {
      if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
        Swal.fire('Success', 'Changed Source  successfully', 'success');
        setTimeout(() => {
          location.reload();
        }, 1000);
        this.dialogRef.close();
      }
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
