import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnyAdapter } from 'src/app/core/api/adapters/any-adapter.service';
import { ApiService } from 'src/app/core/api/api.service';
import { GetDoctorCommand } from 'src/app/main/lead/commands/get-doctor.command';
import { GetNotInterestedCommand } from 'src/app/main/lead/commands/get-notInterested.command';
import { GetTreatmentTypesCommand } from 'src/app/main/lead/commands/get-treatment-types.command';
import { SurgeryScheduleAdapter } from '../adapters/surgery-schedule.adapter.service';
import { WhatsappMessageCommand } from 'src/app/main/lead/commands/whatsapp-message.command';
import { CustomerEscalationCommand }  from 'src/app/main/lead/commands/customer-escalation.command';
import { CabRequest } from 'src/app/main/lead/commands/cab-request.command';
import {meetingRoomCommand } from 'src/app/main/lead/commands/meeting-room.command';
import { GetWhatsappTemplate } from 'src/app/main/lead/commands/whatsapp-message.command';
import { SaveSurgeryScheduleCommand } from '../commands/add-surgery-schedule.command';
import { GetHospitalByDoctorCommand } from '../commands/get-hospital-by-doctor.command';
import { GetLeadOwnerCommand } from '../commands/get-lead-owner.command';
import { GetMasterTags } from '../commands/get-master-tags.command';
import { GetSupervisorCommand } from '../commands/get-supervisor.command';
import { GetSurgeryScheduleCommand } from '../commands/get-surgery-schedule.command';
import { SaveSupervisorCommand } from '../commands/save-supervisor.command';
import { UpdateLeadDirectSurgery } from '../commands/update-lead-direct-surgery.command';
import { SaveTags } from '../commands/save-tags.command';
import { Schedule } from '../models/schedule.model';
import { GetDateByDoctorHospitalCommand } from '../commands/get-date-by-doctor-hospital.command';
import { GetSlotByDateCommand } from '../commands/getSlotByDate.command';
import { GenerateInvoiceCommand } from '../commands/get-generate-invoice.command';
import { SaveReferralCodeCommand } from '../commands/save-referral-code.command';
import { CabCostSave } from 'src/app/main/lead/commands/cab-cost-save.command';
import { SaveLeadSourceCommand } from '../commands/save-lead-source.command';

@Injectable({
  providedIn: 'root'
})
export class SurgeryScheduleService {

  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly scheduleAdapter: SurgeryScheduleAdapter
  ) { }

  getHospitals(docId: string): Observable<any> {
    const command = new GetHospitalByDoctorCommand(this.apiService, this.anyAdapter, docId);
    return command.execute().pipe(map(resp =>  resp.data));
  }

  getDoctors(leadId: string): Observable<any> {
    const command = new GetDoctorCommand(this.apiService, this.anyAdapter, leadId);
    return command.execute().pipe(map(resp => resp.data));
  }

 
  getDateByDoctorHospital(docId: string,hosId:string): Observable<any> {
    const command = new GetDateByDoctorHospitalCommand(this.apiService, this.anyAdapter, docId,hosId);
    return command.execute().pipe(map(resp =>  resp));
  }
  
  GetSlotByDate(docId: string,hosId:string,date:string): Observable<any> {
    const command = new GetSlotByDateCommand(this.apiService, this.anyAdapter, docId,hosId,date);
    return command.execute().pipe(map(resp =>  resp));
  }


  getNotInterested(event: string): Observable<any> {
    const command = new GetNotInterestedCommand(this.apiService, this.anyAdapter, event);
    return command.execute().pipe(map(resp => resp.data));
  }
  getSurgerySchedule(leadId: string): Observable<any> {
    const command = new GetSurgeryScheduleCommand(this.apiService, this.scheduleAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute().pipe(map(resp => resp));
  }

  getSupervisorList(): Observable<any> {
    const command = new GetSupervisorCommand(this.apiService, this.anyAdapter);
    return command.execute().pipe(map(resp => resp.data));
  }

  addSurgerySchedule(data: Schedule): Observable<any> {
    const command =  new SaveSurgeryScheduleCommand(this.apiService, this.scheduleAdapter);
    command.parameters = {
      data,
      reportProgress: true
    };
    command.showLinearLoader = true;
    return command.execute();
  }

  updateLeadDirectSurgery(data: Schedule): Observable<any> {
    const command  = new UpdateLeadDirectSurgery(this.apiService, this.anyAdapter);
    command.parameters = {
      data
    };
    command.showLinearLoader = true;
    return command.execute();
  }

  saveSupervisor(data): Observable<any> {
    const command  = new SaveSupervisorCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data,
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  saveReferralCode(data): Observable<any> {
    const command  = new SaveReferralCodeCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data,
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  
  SaveLeadSourceCommand(data): Observable<any> {
    const command  = new SaveLeadSourceCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data,
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  
  generateInvoice(data): Observable<any> {
    const command  = new GenerateInvoiceCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data,
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  saveTags(data): Observable<any> {
    const command  = new SaveTags(this.apiService, this.anyAdapter);
    command.parameters = {
      data
    };
    command.showLinearLoader = true;
    return command.execute();
  }

  getLeadOwner(): Observable<any> {
    const command = new GetLeadOwnerCommand(this.apiService, this.anyAdapter);
    return command.execute().pipe(map(resp => resp.data));

  }
  getMasterTags(): Observable<any> {
    const command = new GetMasterTags(this.apiService, this.anyAdapter);
    return command.execute().pipe(map(resp => resp.data));

  }

  getTreatmentTypeList(serviceId) {
    const command = new GetTreatmentTypesCommand(this.apiService, this.anyAdapter, serviceId);
    return command.execute().pipe(map(resp => resp.data));
  }

  onSendSms(payload): Observable<any> {
    const command = new WhatsappMessageCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data: payload
    };
    return command.execute();
  }


  getWhatsappTemplateList(): Observable<any> {
    const command = new GetWhatsappTemplate(this.apiService, this.anyAdapter);
    return command.execute();
}

onCreateMeeting(payload): Observable<any> {
  const command = new meetingRoomCommand(this.apiService, this.anyAdapter);
  command.parameters = {
    data: payload
  };
  return command.execute();
}

CustomerEscalation(payload): Observable<any> {
  const command = new CustomerEscalationCommand(this.apiService, this.anyAdapter);
  command.parameters = {
    data: payload
  };
  return command.execute();
}
CabRequest(payload): Observable<any> {
  const command = new CabRequest(this.apiService, this.anyAdapter);
  command.parameters = {
    data: payload
  };
  return command.execute();
}
CabCostSave(payload): Observable<any> {
  const command = new CabCostSave(this.apiService, this.anyAdapter);
  command.parameters = {
    data: payload
  };
  return command.execute();
}
}
 