import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mpms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'medfin-admin';
  hideHeaderOnLogin = false;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((_) => {
      this.hideHeaderOnLogin = this.router.url === '/login' ? true : false;
    });
  }
}
