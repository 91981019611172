import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { PostAPICommand } from 'src/app/core/api/commands';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class WhatsappMessageCommand<T> extends PostAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/v2/whatsapp`);
    }
}


export class GetWhatsappTemplate<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/content/canned-whatsapp-template-list`);
    }
}