<mat-card>
    <mat-card-header>
        <mat-card-title>{{formTitle}}</mat-card-title>
        <span class="spacer"></span>
        <button mat-icon-button (click)="onCloseDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content  class="padding-4">
        <form [formGroup]="dynamicForm" *ngIf="dynamicForm">
            <div class="row customMatFields">
                <ng-container *ngFor="let data of formData; let i = index">
                    <div class="col-md-3 pb-40" *ngIf="data.type === 'text'" >
                            <mat-label>{{data.label}}</mat-label>
                            <span *ngIf="data.dataType=='number'">
                                <input matInput type="number"  autocomplete="off" [formControlName]="data.key" />
                               
                            </span>

                            <input *ngIf="data?.dataType!=='number'" matInput type="text"  autocomplete="off" [formControlName]="data.key">
                        <mpms-input-validation [control]="dynamicForm.get(data.key)" [isShowErrorMsg]="showErrorMsg"></mpms-input-validation>
                    </div>
                    <div class="col-md-3 pb-40 radioGroup" *ngIf="data.type === 'select'" >
                        <mat-label>{{data.label}}</mat-label>
                        <mat-radio-group aria-label="Select an option"  [formControlName]="data.key">
                            <mat-radio-button *ngFor="let item of data.values" [value]="item.value">{{item.option}}</mat-radio-button>
                          </mat-radio-group>
                        <mpms-input-validation [control]="dynamicForm.get(data.key)" [isShowErrorMsg]="showErrorMsg"></mpms-input-validation>
                    </div>
                  
                    <div class="col-md-3 pb-40" *ngIf="data.type === 'textarea'" >
                        <mat-label>{{data.label}}</mat-label>
                        <textarea rows="1" matInput type="text"  autocomplete="off" [formControlName]="data.key"></textarea>
                        <mpms-input-validation [control]="dynamicForm.get(data.key)" [isShowErrorMsg]="showErrorMsg"></mpms-input-validation>
                    </div>
                    <div class="col-md-3 pb-40" *ngIf="data.type === 'dropdown'" >
                            <mat-label>{{data.label}}</mat-label>
                                                     
                            <mat-select  autocomplete="off" [formControlName]="data.key">
                                <mat-option *ngFor="let item of data.values" [value]="item.option">{{item.value}}</mat-option>
                            </mat-select>
                        <mpms-input-validation [control]="dynamicForm.get(data.key)" [isShowErrorMsg]="showErrorMsg"></mpms-input-validation>
                       
                        <input style="margin-top: 5%;" *ngIf="data.label === 'Surgery'" type="text" value="{{data.preSelected}}"> 
                    </div>

                    <div class="col-md-3 pb-40" *ngIf="data.type === 'multiselect'" >
                        <mat-label>{{data.label}}</mat-label>
                     
                        <mat-select [formControlName]="data.key"  multiple>                            
                            <mat-option *ngFor="let item of data.values" [value]="item.option">{{item.value}}</mat-option>
                        </mat-select>       
                        <!-- <mat-select  autocomplete="off" [formControlName]="data.key">
                            <mat-option *ngFor="let item of data.values" [value]="item.option">{{item.value}}</mat-option>
                        </mat-select>
                    <mpms-input-validation [control]="dynamicForm.get(data.key)" [isShowErrorMsg]="showErrorMsg"></mpms-input-validation>
                    -->
                    <!-- <input style="margin-top: 5%;" *ngIf="data.label === 'Surgery'" type="text" value="{{data.preSelected}}">  -->
                </div>

                </ng-container>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button class="mr-20" (click)="onCloseDialog()"> Close </button>
        <button mat-raised-button (click)="onSubmit()" color="primary"> Add </button>
    </mat-card-actions>
</mat-card>