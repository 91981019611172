<div class="pagination-container">
  <div class="jumpTo w-50">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Enter page number" [(ngModel)]="pageNumber">
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="goToPage()">Go to Page</button>
  </div>
  <mat-paginator class="w-50" [length]="dataCount" [pageSize]="limit" [pageSizeOptions]="[limit]" (page)="handlePagination($event)">

  </mat-paginator>
</div>
