import { environment } from 'src/environments/environment';
import { IAdapter } from '../../api/adapters/i-adapter';
import { ApiService } from '../../api/api.service';
import { PostAPICommand } from '../../api/commands';
import { PutAPICommand } from '../../api/commands/put-api.command';



export class ForgotPasswordCommand<T> extends PutAPICommand<T> {
    constructor(apiService: ApiService, adapter: IAdapter<T>) {
        super(apiService, adapter, `${environment.apiEndpoint}/user/password`);
    }
}
