import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';
import { Services } from '../../models/services.model';

@Component({
  selector: 'mpms-service-dropdown',
  templateUrl: './service-dropdown.component.html',
  styleUrls: ['./service-dropdown.component.scss']
})
export class ServiceDropdownComponent implements OnInit {
  services: Services;
  @Input() selectedService: number;
  @Output() emitSelectedService =  new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    const tempServices = localStorage.getItem(AppConstants.LS_SERVICE_KEY);
    this.services =  tempServices ?  JSON.parse(tempServices) : [];
  }

  onServiceChange(e) {
    this.emitSelectedService.emit(e);
  }
}
