import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class SMSHistoryCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, leadId: string, startIndex: number,
                count: number, startDate?: string | null, endDate?: string | null) {
                    if (startDate && endDate) {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/sms/history/${leadId}?startindex=${startIndex}&count=${count}&startDate=${startDate}&endDate=${endDate}`);
                    } else {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/sms/history/${leadId}?startindex=${startIndex}&count=${count}&startDate=&endDate=`);
                    }
    }
}
