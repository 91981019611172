<div class="menu-wrapper">
  <div class="container">
  <ul class="right-menu">
    <li *ngFor="let menu of megaMenu">
      <a href="#">{{ menu.parentMenuName }}</a>
      <ul
        class="inner-list"
        [ngClass]="{
          columnTwo: menu.adminMenuItems.length > 8,
          columnThree: menu.adminMenuItems.length > 16
        }"
      >
        <li *ngFor="let subMenu of menu.adminMenuItems; let i = index">
          <a href="#">{{ subMenu.childMenuName }}</a>
        </li>
      </ul>
    </li>
  </ul>
</div>
</div>
