import { Injectable } from '@angular/core';
import { IAdapter } from '../../api/adapters/i-adapter';
import { User } from '../models/user.model';


@Injectable()

export class CurrentUserAdapter implements IAdapter<User> {
    adaptToModel(resp: any): User {
        const user = new User(resp);
        return user;
    }

    adaptFromModel(data: User): any {
        return data;
    }
}
