import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputValidationComponent } from './components/input-validation/input-validation.component';
import { HeaderComponent } from './components/header/header.component';
import { AutocompleteDropdownComponent } from './components/autocomplete-dropdown/autocomplete-dropdown.component';
import { CityDropdownComponent } from './components/city-dropdown/city-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CityFacadeService } from './facades/city-facade.service';
import { CityAdapter } from './adapters/city.adapter.service';
import { DoctorDropdownComponent } from './components/doctor-dropdown/doctor-dropdown.component';
import { DoctorFacadeService } from './facades/doctor-facade.service';
import { HospitalDropdownComponent } from './components/hospital-dropdown/hospital-dropdown.component';
import { ServiceDropdownComponent } from './components/service-dropdown/service-dropdown.component';
import { ServicesAdapter } from './adapters/service.adapter.service';
import { FilterComponent } from './components/filter/filter.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { SurgeryScheduleComponent } from './components/surgery-schedule/surgery-schedule.component';
import { SurgeryScheduleAdapter } from './adapters/surgery-schedule.adapter.service';
import { AssignSupervisorComponent } from './components/assign-supervisor/assign-supervisor.component';
import { ChangeLeadOwnerComponent } from './components/change-lead-owner/change-lead-owner.component';
import { BackdatedAppointmentsComponent } from './components/backdated-appointments/backdated-appointments.component';
import { AssociateTagsLeadComponent } from './components/associate-tags-lead/associate-tags-lead.component';
import { SmsDoctorDropdownComponent } from './components/sms-doctor-dropdown/sms-doctor-dropdown.component';
import { UpdateReferralCodeComponent } from './components/update-referral-code/update-referral-code.component';
import { UpdateLeadSourceComponent } from './components/update-lead-source/update-lead-source.component';


const MODULES = [
  FormsModule,
  CommonModule,
  MaterialModule,
  ReactiveFormsModule,
  NgSelectModule,
  
];

const COMPONENTS = [
  InputValidationComponent,
  HeaderComponent,
  AutocompleteDropdownComponent,
  CityDropdownComponent,
  DoctorDropdownComponent,
  HospitalDropdownComponent,
  ServiceDropdownComponent,
  FilterComponent,
  PaginationComponent,
  SmsDoctorDropdownComponent,
  
  DynamicFormComponent,
  SurgeryScheduleComponent,
  AssignSupervisorComponent,
  ChangeLeadOwnerComponent
];


@NgModule({
  declarations: [
    ...COMPONENTS,
    BackdatedAppointmentsComponent,
    AssociateTagsLeadComponent,
    UpdateReferralCodeComponent,
    UpdateLeadSourceComponent
  ],
  imports: [
   ...MODULES
  ],
  providers: [CityFacadeService, DoctorFacadeService, CityAdapter, ServicesAdapter, SurgeryScheduleAdapter],
  exports: [...MODULES, ...COMPONENTS]
})
export class SharedModule { }
