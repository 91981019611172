import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { PostAPICommand } from 'src/app/core/api/commands';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class meetingRoomCommand<T> extends PostAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/video/create-meeting`);
    }
}


export class GetMeetingRoomDetails<T> extends GetAPICommand<T> {
    // constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
    //                super(apiService, anyAdapter , `${environment.apiEndpoint}/content/canned-whatsapp-template-list`);
    // }
}