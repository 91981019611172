import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DoctorFacadeService } from '../../facades/doctor-facade.service';
import { Hospitals } from '../../models/hospital.model';

@Component({
  selector: 'mpms-hospital-dropdown',
  templateUrl: './hospital-dropdown.component.html',
  styleUrls: ['./hospital-dropdown.component.scss']
})
export class HospitalDropdownComponent implements OnInit {
  hospitals: Hospitals[];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onEmitHospital = new EventEmitter();
  constructor(
    private readonly doctorService: DoctorFacadeService
  ) { }

  ngOnInit(): void {
    this.doctorService.hospitals$.subscribe(hospitals => this.hospitals = hospitals);
  }

  getSelectedHospital(hospitalId): void {
    this.onEmitHospital.emit(hospitalId);
  }

}
