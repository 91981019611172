import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class SurgeryPricing<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, city: string , service,hospital,doctor,viewType) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/content/price/filter?city=${city}&service=${service}&hospital=${hospital}&doctor=${doctor}&viewType=${viewType}`);
    }
}
