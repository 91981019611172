import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class getHospitalByDoctorCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, docId: any,) {     
                   
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/location/hospital/doctor/${docId}`);                   
    }
}
export class getHospitalByDoctorandLeadCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, docId: any,leadId:string,insurance:any) {     
                   
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/content/insurance-doctor-hospitals?insurance=${insurance}&lead=${leadId}&doctor=${docId}`);                   
    }
}