import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { Services } from '../models/services.model';



export class ServicesAdapter implements IAdapter<Services> {
    adaptToModel(resp: Services[]): any {
        const response = [];
        for (let i = 0; i < resp.length; i++) {
            const obj = {
                parentServiceId: resp[i].serviceId,
                parentServiceName: resp[i].serviceName,
                id: resp[i].serviceId,
                name: resp[i].serviceName
            }
            response.push(obj);
            if (resp[i].serviceList) {
                for (let j = 0; j < resp[i].serviceList.length; j++) {
                    let obj2 = {
                        parentServiceId: resp[i].serviceId,
                        parentServiceName: resp[i].serviceName,
                        id: resp[i].serviceList[j]?.serviceId,
                        name: resp[i].serviceList[j]?.serviceName
                    }
                    response.push(obj2);
                }
            }
        }
        return response;
    }

    adaptFromModel(data: Services): Services {
        return data;
    }
}
