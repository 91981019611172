import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthFacadeService } from '../core/auth/facades/auth-facade.service';
import { HttpStatusCode } from '../core/enums/http-status-codes.enum';
import { CustomValidators } from '../shared/validators/custom-validators';

@Component({
  selector: 'mpms-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Output() showLoginForm = new EventEmitter(false);

  forgotPasswordForm: FormGroup;
  isShowErrorMsg = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthFacadeService)
    { }

  ngOnInit(): void {
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: [null , [Validators.required, CustomValidators.emailValidator]],
      eventType: ['reset-password']
    });
  }

  onShowLoginForm(): void {
    this.showLoginForm.emit(true);
  }

  onSubmit(): void {
    this.isShowErrorMsg = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.authService.onForgotPassword(this.forgotPasswordForm.getRawValue()).subscribe(response => {
      if (response.statusCode === HttpStatusCode.SUCCESSFUL) {
        Swal.fire('',
        'Success!',
        'success'
        ).then(() => {
          this.showLoginForm.emit(true);
        });
      }
    });

  }

}
