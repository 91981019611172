import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/core/auth/models/user.model';
import { AppConstants } from '../../constants/app.constants';

@Component({
  selector: 'mpms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }
  megaMenu: Menu[];

  ngOnInit(): void {
    const tempMenu = localStorage.getItem(AppConstants.LS_USER_KEY);

    this.megaMenu = tempMenu ? JSON.parse(tempMenu)?.menu : [];
  }

}
