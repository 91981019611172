import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthFacadeService } from "../auth/facades/auth-facade.service";
import { CookieService } from 'ngx-cookie-service';
import { HttpStatusCode } from "../enums/http-status-codes.enum";


@Injectable()
export class LeadDetailGuardService implements CanActivate {
  
    constructor(private readonly auth: AuthFacadeService,
        private readonly router: Router, private readonly cookieService: CookieService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
       
          
        const token=this.cookieService.get('crossDomainCookie')
        setTimeout(()=>{
            console.log("crossDomainCookie")
        },1000)
        let decodeToken = token && JSON.parse(decodeURIComponent(token));
        setTimeout(()=>{
            console.log("decode token")
        },1000)
          
            if (decodeToken) {
                localStorage.setItem("cookieToken", decodeToken);
                this.auth.getUserDataFromToken(decodeToken).subscribe(response => {
                    if (response.statusCode === HttpStatusCode.SUCCESSFUL) {
                        setTimeout(()=>{
                            console.log("token res")
                        },1000)
                    
                        this.auth.storeDataToStorage(response, 'lead');
                  
                    }
                    return true;
                })
        
        } else if (!this.auth.getAuthToken()) {  
                this.router.navigate(['login'], {
                    queryParams: { returnUrl: state.url },
                    replaceUrl: true
                });
                return false;
            }

        

        return true;
    }
}