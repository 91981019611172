<mat-card>
    <mat-card-header>
      <mat-card-title>Update Lead Owner</mat-card-title>
      <span class="spacer"></span>
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content class="padding-4 customMatFields">
      <form [formGroup]="leadOwnerForm" *ngIf="leadOwnerForm">
        <div class="row">
          <div class="col-md-4">
            <mat-label>Lead Owner List</mat-label>
            <mpms-autocomplete-dropdown [data]="leadOwners" [bindLabel]="'owner'" bindValue="ownerEmail"
              [selectedItem]="leadOwnerForm.get('owner').value" (onOptionChange)="onSelectOwner($event)">
            </mpms-autocomplete-dropdown>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button class="mr-20" (click)="onCloseDialog()"> Close </button>
      <button mat-raised-button (click)="saveLeadOwner()" color="primary">Add </button>
    </mat-card-actions>
  </mat-card>