<mat-card>
  <mat-card-header>
    <mat-card-title>Look Up Slots</mat-card-title>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content class="padding-4 customMatFields">
    <form [formGroup]="backupDateForm">

      <div class="row customMatFields">
        <div class="col-md-3 pb-40">
          <mat-label>Doctor </mat-label>
          <mpms-autocomplete-dropdown [data]="doctors" placeholder="Doctor"
            [selectedItem]="backupDateForm.get('doctor').value ? backupDateForm.get('doctor').value : ''"
            bindValue="id" bindLabel="name" (onOptionChange)="getHospitals($event)">
          </mpms-autocomplete-dropdown>
        </div>

        <div class="col-md-3 pb-40">
          <mat-label>Hospital</mat-label>
          <mpms-autocomplete-dropdown [data]="hospitals"
            [selectedItem]="backupDateForm.get('hospital').value ? backupDateForm.get('hospital').value : ''"
            placeholder="Hospital" bindValue="id" bindLabel="name" (onOptionChange)="selectHospitals($event)">
          </mpms-autocomplete-dropdown>
        </div>

        <div class="col-md-3 pb-40 startDate minus-mt-25">
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="picker1" autocomplete="off" (click)="picker1.open()" [min]="minFromDate"
            (dateChange)="onSelectFromDate($event)" formControlName="fromDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>

        </div>
        <div class="col-md-3 pb-40 startDate minus-mt-25">
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="picker2" autocomplete="off" (click)="picker2.open()"
            formControlName="toDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>

        </div>
      </div>
      <div class="row customMatFields">
        <div class="col-md-3 pb-40">
          <mat-label>Previous days </mat-label>
          <mat-select autocomplete="off" (selectionChange)="onSelectPreviousDays($event)">
            <mat-option value="">Please select</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </div>

        <div class="col-md-3 pb-40">
          <mat-label>Appointment Type </mat-label>
          <mat-select autocomplete="off" formControlName="appointmentType">
            <mat-option value="">Please select</mat-option>
            <mat-option value="Chargeable">Chargeable</mat-option>
            <mat-option value="Free">Free Appointment</mat-option>
            <mat-option value="Post Surgery">Post surgery follow up appointment </mat-option>
            <mat-option value="Pre Surgery">Pre Surgery appointment</mat-option>
            <mat-option value="Second Opinion">Second Opinion</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row content-center">
        <div>
          <button type="button" class="btn green-meadow" (click)="getSlotes()">Search</button>
          <button type="button" class="btn red" (click)="clearLookUpCal()">Clear</button>
        </div>
      </div>
    </form>
    <form>
      <div class="row">
        <div class="col-md-4">
          <mat-label>Locality </mat-label>
          <input class="input" type="text" #addresstext>
        </div>
      </div>
      <div class="row" *ngIf="showSlots">
        <mat-tab-group mat-align-tabs="center" class="tab-group">
          <mat-tab label="Available Slots">
            <cdk-accordion class="example-accordion">
              <cdk-accordion-item *ngFor="let doctor of slotList; let index = index;" #accordionItem="cdkAccordionItem"
                class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                  {{ doctor.date }}
                  <span class="example-accordion-item-description">
                    Click to {{ accordionItem.expanded ? 'close' : 'open' }}
                  </span>
                </div>
                <div style="display: flex;">
                <div class="example-accordion-item-body" role="region"
                  *ngFor="let slot of doctor.slots; let index = index;"
                  [ngClass]="selectSlot == slot.slotId ? 'booked' : ''"
                  (click)="markLookup(slot.slotId, doctor.date, slot.fromTime )"
                  [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index">
                  {{slot.fromTime}}-{{slot.toTime}}
                </div>
                </div>
              </cdk-accordion-item>
            </cdk-accordion>
          </mat-tab>

        </mat-tab-group>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button (click)="onCloseDialog()"> Close </button>
    <button mat-raised-button (click)="onSubmit()" color="primary" *ngIf="showSlots">Add </button>
  </mat-card-actions>
</mat-card>