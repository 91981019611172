
    <form class="forget-form" [formGroup]="forgotPasswordForm">
      <h3 class="">Forget Password ?</h3>
      <p>Enter your e-mail address below to reset your password.</p>
      <div class="form-group">
        <input class="form-control placeholder-no-fix" type="email" formControlName="email"  placeholder="Email"/>
        <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="forgotPasswordForm.get('email')"></mpms-input-validation>
      </div>
      <div class="form-actions">
        <button mat-raised-button (click)="onShowLoginForm()">Back</button>
        <button mat-raised-button color="primary" class="pull-right" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </form>