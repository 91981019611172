import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { PostAPICommand, PutAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';

export class addDoctorHospital<T> extends PutAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/insurance-estimate-doctor`);
    }
   
}


