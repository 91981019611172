import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnyAdapter } from 'src/app/core/api/adapters/any-adapter.service';
import { ApiService } from 'src/app/core/api/api.service';
import { DynamicFormContentCommand } from '../commands/dynamic-content.command';
import { LeadDetailsCommand } from '../commands/lead-details.command';
import {TagsByLead} from '../commands/tags-by-lead.command';
import { LeadHistoryCommand } from '../commands/lead-history.command';
import { SMSHistoryCommand } from '../commands/sms-histoy.command';
import { DuplicateLeadCommand } from '../commands/duplicate-lead.command';
import { UploadedDocumentCommand } from '../commands/uploaded-documents.command';
import { DocumentList } from '../commands/document-list.command';
import { UpdateDocument } from '../commands/update-document.command';
import { MedicalInfo } from '../commands/medical-info.command';
import { CallHistory } from '../commands/call-history.command';
import { LiveStatusCallInfo } from '../commands/live-status.command';
import { AppointmentHistory } from '../commands/appointment-history.command';
import { LeadDocumentLists, InsuranceDocumentLiveStatus } from '../commands/lead-document-lists.command';
import { WPHistoryCommand } from '../commands/WP-history.command';
import { InsuranceEstimateCommand } from '../commands/insurance-estimate.command';
import { InsuranceEstimateDocumentCommand } from '../commands/insurance-estimate.document.command';
import { SaveDynamicFormContentCommand } from '../commands/saveDynamicFormContent.command';
import { AdditionalDoctor, UpdateAdditionalDoctor, DoctorTypeList, DoctorListByLead, AddAdditionalDoctor ,DoctorListByLeadandInsurance} from '../commands/additional-doctor.command';
import { DownlodableDocuments, DownloadDocument } from '../commands/downloadble-documents.command';
import { PaymentHistory, PaymentStatus } from '../commands/payment-history.command';
import { QuoteHistory } from '../commands/quote-history.command';
import { ChatHistory } from '../commands/chat-history.command';
import { History } from '../models/lead-history.model';
import { HttpClient } from '@angular/common/http';
import { InsuranceTpaCommand } from '../commands/insurance-tpa.command';
import { submitInsuranceEstimate } from '../commands/submit-insurance-estimate.command';
import { InsuranceEstimateInfoUpdate } from '../commands/insurance-estimate-info.update';
import { updateInsuranceEstimate } from '../commands/update-insurance-estimate.command';
import { getHospitalByDoctorCommand } from '../commands/get-hospital-by-doctor';
import { getHospitalByDoctorandLeadCommand } from '../commands/get-hospital-by-doctor';
import { addDoctorHospital } from '../commands/addDoctorHospitalPut.command';

import { submitEMIEstimate } from '../commands/submit-emi-estimate.command';
import { UpdateEMIEstimate } from '../commands/update-emi-estimate.command';
import { rejectEMIEstimate } from '../commands/reject-emi-estimate.command';
import { salesInfoCommand } from '../commands/sales-info.command';
import { changeSurgeryAddDoctorHospital } from '../commands/changeSurgeryAddDoctorHospital';
import { EMIEstimateCommand } from '../commands/EMI-estimate.command';
import {AttributePricing} from '../commands/attribute-pricing.command';
import { GetRelationNameCommand } from '../commands/get-relation-name.command';
import { GetTaskTypeCommand } from '../commands/get-task-type-command';
import { TASKSCommand } from '../commands/tasks.command';
import { UpdateTasks } from '../commands/update-tasks.command';
import { AttributeHospital } from '../commands/surgery-hospital-list';
import { EMIEstimateDocumentCommand } from '../commands/emi-estimate.document.command';
import { SurgeryPricing } from '../commands/surgery-pricing.command';
import { feedbackInfoCommand } from '../commands/feedback-info.command';
import { VendorListCommand } from '../commands/vendor-list.command';
import { GetSourceNameCommand } from '../commands/get-source-name.command';
import { FollowUp } from '../commands/follow-up.command';
import { GetOwnerNameCommand } from '../commands/get-owner-name.command';
import { submitInsurancePreAuthRequested } from '../commands/submit-insurance-preauth-request.command';
import { cabCostGetCommand } from '../commands/cab-cost-get.command';
import { updateCabCostCommand } from '../commands/update-cab-cost.command';

@Injectable()
export class LeadDetailFacadeService {
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
  ) { }

  getLeadDetails(leadId: string): Observable<any[]> {
    const command = new LeadDetailsCommand(
      this.apiService,
      this.anyAdapter,
      leadId
    );
    command.showLinearLoader = true;
    return command.execute().pipe(map(res=>res.data={...res.data,...res.additionalInfo}));
  }

  getTagsByLeadId(leadId: string): Observable<any[]> {
    const command = new TagsByLead(
      this.apiService,
      this.anyAdapter,
      leadId
    );
    command.showLinearLoader = true;
    return command.execute();
  }

  getLeadHistory(
    leadId: string,
    startIndex: number,
    count: number,
    startDate?: string | null,
    endDate?: string | null,
    notes?:string
  ): Observable<any> {
    const command = new LeadHistoryCommand(
      this.apiService,
      this.anyAdapter,
      leadId,
      startIndex,
      count,
      startDate,
      endDate,
      notes
    );
    command.showLinearLoader = true;
    return command.execute();
  }
  getSmsHistory(
    leadId: string,
    startIndex: number,
    count: number,
    startDate?: string | null,
    endDate?: string | null
  ): Observable<any> {
    const command = new SMSHistoryCommand(
      this.apiService,
      this.anyAdapter,
      leadId,
      startIndex,
      count,
      startDate,
      endDate
    );
    command.showLinearLoader = true;
    return command.execute();
  }
  getTasks(
    leadId: string,
    startIndex: number,
    count: number,
    startDate?: string | null,
    endDate?: string | null,
    task?:string
  ): Observable<any> {
    const command = new TASKSCommand(
      this.apiService,
      this.anyAdapter,
      leadId,
      startIndex,
      count,
      startDate,
      endDate,
      task
    );
    command.showLinearLoader = true;
    return command.execute();
  }
  getUploadedDocuments(
    leadId: string,
    startIndex: number,
    count: number,
    startDate?: string | null,
    endDate?: string | null
  ): Observable<any> {
    const command = new UploadedDocumentCommand(
      this.apiService,
      this.anyAdapter,
      leadId,
      startIndex,
      count
    );
    command.showLinearLoader = true;
    return command.execute();
  }
  getAppointmentHistory(mobile: string): Observable<any> {
    const command = new AppointmentHistory(this.apiService, this.anyAdapter, mobile);
    command.showLinearLoader = true;
    return command.execute();
  }
  getRelationName(): Observable<any> {
    const command = new GetRelationNameCommand(this.apiService, this.anyAdapter);
    // command.showLinearLoader = true;
    return command.execute();
  }
  getSourceName(): Observable<any> {
    const command = new GetSourceNameCommand(this.apiService, this.anyAdapter);
    // command.showLinearLoader = true;
    return command.execute();
  }
  getOwnerName(): Observable<any> {
    const command = new GetOwnerNameCommand(this.apiService, this.anyAdapter);
    // command.showLinearLoader = true;
    return command.execute();
  }
  getTaskType(): Observable<any> {
    const command = new GetTaskTypeCommand(this.apiService, this.anyAdapter);
    // command.showLinearLoader = true;
    return command.execute();
  }
  
  getPaymentHistory(mobile: string): Observable<any> {
    const command = new PaymentHistory(this.apiService, this.anyAdapter, mobile);
    command.showLinearLoader = true;
    return command.execute();
  }
  getQuoteHistory(leadId: string): Observable<any> {
    const command = new QuoteHistory(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute();
  }

  getFollowUp(leadId: string): Observable<any> {
    const command = new FollowUp(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute();
  }

  getChatHistory(mobile: string): Observable<any> {
    const command = new ChatHistory(this.apiService, this.anyAdapter, mobile);
    command.showLinearLoader = true;
    return command.execute();
  }
  getLivePaymentStatus(postedBy: string, orderId): Observable<any> {
    const command = new PaymentStatus(this.apiService, this.anyAdapter);
    command.parameters = {
      data: { postedBy, orderId }
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  // getAttributePricing(payload: any): Observable<any> {
  //   const command = new AttributePricing(this.apiService, this.anyAdapter);
  //   command.parameters = {
  //     data: payload
  //   };
  //   command.showLinearLoader = true;
  //   return command.execute();
  // }

  getSurgeryPricing(city: string, service,hospital,doctor,viewType): Observable<any> {
    const command = new SurgeryPricing(this.apiService, this.anyAdapter, city , service,hospital,doctor,viewType);
    command.showLinearLoader = true;
    return command.execute();
  }

  getAttributeHospital(city: string, service): Observable<any> {
    const command = new AttributeHospital(this.apiService, this.anyAdapter, city , service);
    command.showLinearLoader = true;
    return command.execute();
  }
  getInsuranceEstimateUpdate(token: string): Observable<any> {
    const command = new InsuranceEstimateInfoUpdate(this.apiService, this.anyAdapter, token);
    command.showLinearLoader = true;
    return command.execute();
  }

  getLiveCallStatus(token: string): Observable<any> {
    const command = new LiveStatusCallInfo(this.apiService, this.anyAdapter, token);
    command.showLinearLoader = true;
    return command.execute();
  }

  getMedicalInfo(leadId: string): Observable<any> {
    const command = new MedicalInfo(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute();
  }
  getDocumentList(): Observable<any> {
    const command = new DocumentList(this.apiService, this.anyAdapter);
    command.showLinearLoader = true;
    return command.execute();
  }
  getDocuments(leadId: string, startIndex: number, count: number): Observable<any> {
    const command = new LeadDocumentLists(this.apiService, this.anyAdapter, leadId, startIndex, count);
    command.showLinearLoader = true;
    return command.execute();
  }
  getLiveStatusInsuranceDocument(referenceNumber: string): Observable<any> {
    const command = new InsuranceDocumentLiveStatus(this.apiService, this.anyAdapter, referenceNumber);
    command.showLinearLoader = true;
    return command.execute();
  }
  getAdditionalDoctors(leadId: string): Observable<any> {
    const command = new AdditionalDoctor(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute();
  }
  getDoctorTypeList(): Observable<any> {
    const command = new DoctorTypeList(this.apiService, this.anyAdapter);
    command.showLinearLoader = true;
    return command.execute();
  }
  getDoctorListByLead(leadId: string): Observable<any> {
    const command = new DoctorListByLead(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute();
  }
  getDoctorListByLeadandInsurance(leadId: string, insurance:any): Observable<any> {
    const command = new DoctorListByLeadandInsurance(this.apiService, this.anyAdapter, leadId ,insurance);
    command.showLinearLoader = true;
    return command.execute();
  }
  getDownloadableDocuments(leadId: string): Observable<any> {
    const command = new DownlodableDocuments(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute();
  }
  downloadSelectedDocuments(
    payload: any, leadId: string
  ): Observable<any> {
    const command = new DownloadDocument(this.apiService, this.anyAdapter, leadId);
    command.parameters = {
      data: payload,
      responseType: 'arraybuffer',
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  addAdditionalDoctor(
    payload: any
  ): Observable<any> {
    const command = new AddAdditionalDoctor(this.apiService, this.anyAdapter);
    command.parameters = {
      data: payload
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  updateDoctorStatusAdditionalDoctor(
    id: string,
    status: string
  ): Observable<any> {
    const command = new UpdateAdditionalDoctor(this.apiService, this.anyAdapter);
    command.parameters = {
      data: {
        id,
        status
      }
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  updateCabCost(
    id: string,
    lead:string,
    comments:string,
    postedBy:string,
    status: string
  ): Observable<any> {
    const command = new updateCabCostCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data: {
        id,
        lead,
        comments,
        postedBy,   
        status
      }
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  updateDocumentType(
    docType: string,
    id: string,
    lead: string,
    posted: string,
    source: string
  ): Observable<any> {
    const command = new UpdateDocument(this.apiService, this.anyAdapter);
    command.parameters = {
      data: {
        docType,
        id,
        lead,
        posted,
        source
      }
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  deleteDocument(
    id: string,
    lead: string,
    posted: string,
    source: string
  ): Observable<any> {
    const command = new UpdateDocument(this.apiService, this.anyAdapter);
    command.parameters = {
      data: {
        id,
        lead,
        posted,
        source,
        status: '0'
      }
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  getDuplicateLead(
    mobile: string,
    startIndex: number,
    count: number
  ): Observable<any> {
    const command = new DuplicateLeadCommand(
      this.apiService,
      this.anyAdapter,
      startIndex,
      count
    );
    command.parameters = {
      data: {
        mobile,
        rowCount: count,
        rowIndex: startIndex
      }
    };
    command.showLinearLoader = true;
    return command.execute();
  }

  getDynamicContent(url: string): Observable<any> {
    const command = new DynamicFormContentCommand(
      this.apiService,
      this.anyAdapter,
      url
    );
    command.showLinearLoader = true;
    return command.execute().pipe(map((resp) => resp.data));
  }

  saveDynamicForm(data): Observable<any> {
    const command = new SaveDynamicFormContentCommand(
      this.apiService,
      this.anyAdapter
    );
    command.parameters = {
      data,
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  getCallHistory(
    endDate: string,
    rowCount: number,
    rowIndex: number,
    startDate: string,
    toNumber: string,
    toStatus: string,
    altMobile:string
  ): Observable<any> {
    const command = new CallHistory(
      this.apiService,
      this.anyAdapter
    );
    command.parameters = {
      data: {
        endDate,
        rowCount,
        rowIndex,
        startDate,
        toNumber,       
        toStatus,
        altMobile
      }
    };
    command.showLinearLoader = true;
    return command.execute();
  }

  getWPHistory(leadId: string, startIndex: number, count: number, startDate?: string | null, endDate?: string | null): Observable<any> {
    const command = new WPHistoryCommand(this.apiService, this.anyAdapter, leadId, startIndex, count, startDate, endDate);
    command.showLinearLoader = true;
    return command.execute();
  }
  getSalesInfo(service, city,leadId): Observable<any> {

    const command = new salesInfoCommand(this.apiService, this.anyAdapter, service, city,leadId);
    command.showLinearLoader = true;
    return command.execute();
  }
  getCabCostInfo(lead): Observable<any> {

    const command = new cabCostGetCommand(this.apiService, this.anyAdapter,lead);
    command.showLinearLoader = true;
    return command.execute();
  }
  
  getFeedBackInfo(leadId): Observable<any> {

    const command = new feedbackInfoCommand(this.apiService, this.anyAdapter,leadId);
    command.showLinearLoader = true;
    return command.execute();
  }

  getInsuranceEstimate(leadId: string, startIndex: number, count: number,
    startDate?: string | null, endDate?: string | null): Observable<any> {
    const command = new InsuranceEstimateCommand(this.apiService, this.anyAdapter, leadId, startIndex, count, startDate, endDate);
    command.showLinearLoader = true;
    return command.execute();
  }
  getEMIEstimate(leadId: string, startIndex: number, count: number, startDate?: string | null, endDate?: string | null): Observable<any> {
    const command = new EMIEstimateCommand(this.apiService, this.anyAdapter, leadId, startIndex, count, startDate, endDate);
    command.showLinearLoader = true;
    return command.execute();
  }

  getInsuranceEstimateDocumentList(leadId: string, startIndex: number,
    count: number, startDate?: string | null, endDate?: string | null): Observable<any> {
    const command = new InsuranceEstimateDocumentCommand(this.apiService, this.anyAdapter, leadId, startIndex, count, startDate, endDate);
    command.showLinearLoader = true;
    return command.execute();
  }
  getEMIEstimateDocumentList(leadId: string, startIndex: number,
    count: number, startDate?: string | null, endDate?: string | null): Observable<any> {
    const command = new EMIEstimateDocumentCommand(this.apiService, this.anyAdapter, leadId, startIndex, count, startDate, endDate);
    command.showLinearLoader = true;
    return command.execute();
  }


  getInsuranceTpaList(): Observable<any> {
    const command = new InsuranceTpaCommand(this.apiService, this.anyAdapter);
    command.showLinearLoader = true;
    return command.execute();
  }

  getVendorList(): Observable<any> {
    const command = new VendorListCommand(this.apiService, this.anyAdapter);
    command.showLinearLoader = true;
    return command.execute();
  }
  

  getHospitalDropDown(docId: any): Observable<any> {
    const command = new getHospitalByDoctorCommand(this.apiService, this.anyAdapter, docId);
    command.showLinearLoader = true;
    return command.execute();
  }
  getHospitalByDoctorandLead(docId: any,leadId:string,insurance:any): Observable<any> {
    const command = new getHospitalByDoctorandLeadCommand(this.apiService, this.anyAdapter, docId , leadId , insurance);
    command.showLinearLoader = true;
    return command.execute();
  }
  submitInsuranceEstimate(req: any): Observable<any> {
    const command = new submitInsuranceEstimate(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  submitInsurancePreAuthRequested(req: any): Observable<any> {
    const command = new submitInsurancePreAuthRequested(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  submitEMIEstimate(req: any): Observable<any> {
    const command = new submitEMIEstimate(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  addDoctorHospital(req: any): Observable<any> {
    const command = new addDoctorHospital(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  updateInsuranceEstimate(req: any): Observable<any> {
    const command = new updateInsuranceEstimate(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  UpdateEMIEstimate(req: any): Observable<any> {
    const command = new UpdateEMIEstimate(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  UpdateTasks(req: any): Observable<any> {
    const command = new UpdateTasks(this.apiService, this.anyAdapter,req);
   
    command.showLinearLoader = true;
    command.parameters = {
      data: req
    };
    return command.execute();
  }

  rejectEMIEstimate(req: any): Observable<any> {
    const command = new rejectEMIEstimate(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
  changeSurgeryAddDoctorHospital(req: any): Observable<any>{
    const command = new changeSurgeryAddDoctorHospital(this.apiService, this.anyAdapter);
    command.parameters = {
      data: req
    };
    command.showLinearLoader = true;
    return command.execute();
  }
}
