import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand,PutAPICommand,PostAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class AdditionalDoctor<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>,leadId:string) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/lead-additional-doctor?lead=${leadId}`);  
    }
}
export class UpdateAdditionalDoctor<T> extends PutAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/lead-additional-doctor`);
                   
    }
}
export class DoctorTypeList<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/content/doctor-type-listing`);  
    }
}
export class DoctorListByLead<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>,leadId:string) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/doctor/list/admin?lead=${leadId}`);  
    }
}
export class AddAdditionalDoctor<T> extends PostAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/lead-additional-doctor`);  
    }
}

export class DoctorListByLeadandInsurance<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>,leadId:string,insurance:any) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/content/insurance-doctors?insurance=${insurance}&lead=${leadId}`);  
    }
}