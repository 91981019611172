import { DatePipe } from '@angular/common';
import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { Schedule } from '../models/schedule.model';




export class SurgeryScheduleAdapter implements IAdapter<Schedule> {
    adaptToModel(resp: any): Schedule {
        const data = resp.data;
        const schedule = new Schedule();

        if (data) {
            schedule.admissionDate = data.admissionDate.split(' ')[0];
            schedule.admissionTime = data.admissionDate.split(' ')[1];
            schedule.dischargeDate  = data.dischargeDate.split(' ')[0];
            schedule.dischargeTime = data.dischargeDate.split(' ')[1];
            schedule.doctorId = data.doctorId;
            schedule.event = data.event;
            schedule.hospitalId = data.hospitalId;
            schedule.lead = data.lead;
            schedule.newValue = data.newValue;
            schedule.posted = data.posted;
            schedule.surgeryDate = data.surgeryDate.split(' ')[0];
            schedule.surgeryTime = data.surgeryDate.split(' ')[1];
            schedule.supervisor = data.supervisor;
            schedule.treatmentType = data.treatmentType;
            schedule.treatmentTypeArray=data.treatmentTypeArray;
            schedule.surgeryHospitalId = data?.surgeryHospitalId;
            schedule.surgeryDocId = data?.surgeryDocId;
        }
        return schedule;


    }

    adaptFromModel(data: Schedule): any {
        console.log(data);
        const {admissionTime, dischargeTime, surgeryTime, ...payload} = data;
        payload.admissionDate = `${new DatePipe('en').transform(data.admissionDate, 'yyyy-MM-dd')} ${admissionTime}`;
        // payload.dischargeDate = `${new DatePipe('en').transform(data.dischargeDate, 'yyyy-MM-dd')} ${dischargeTime}`;
        payload['reminderDateTime'] = `${new DatePipe('en').transform(data.surgeryDate, 'yyyy-MM-dd')} ${data.surgeryTime}`;
        delete payload.surgeryDate;
        return payload;
    }
}
