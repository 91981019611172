<div class="login-box login">
  <div class="logo">
    <a href="/">
      <img alt="logo" class="logo-default" src="https://newadmin.medfin.in/assets/images/login/logo.png" />
    </a>
  </div>
  <div class="content">
    <!-- BEGIN LOGIN FORM -->
    <form class="login-form" [formGroup]="loginForm" *ngIf="!isShowForgotPasswordForm && !isShowOtpForm">
      <h3 class="form-title">Sign In with Email</h3>

      <div class="form-group" [class.has-error] = "loginForm.get('email')?.touched && loginForm.get('email')?.invalid && isShowErrorMsg">
        <div class="input-icon">
          <i class="fa fa-user"></i>
          <input class="form-control" type="email" autocomplete="off" placeholder="Email" required
            formControlName="email" />
          <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="loginForm.get('email')"></mpms-input-validation>
        </div>
      </div>

      <div class="form-group" [class.has-error] = "loginForm.get('password')?.touched && loginForm.get('password')?.invalid && isShowErrorMsg">
        <div class="input-icon">
          <i class="fa fa-lock"></i>
          <input class="form-control placeholder-no-fix valid" formControlName="password" type="password" autocomplete="off"
            placeholder="Password" />
          <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="loginForm.get('password')"></mpms-input-validation>
        </div>
      </div>

      <div class="form-actions">
        <label>
          <mat-checkbox>Remember me</mat-checkbox>
        </label>
        <button mat-raised-button color="primary" class="green-haze" (click)="onLogin()">
          Login
          <span class="material-icons"> arrow_circle_right </span>
        </button>
      </div>
      <div class="forget-password">
        <h4>Forgot your password ?</h4>

        <p>no worries, click <a href="javascript:;" (click)="showForgotPasswordForm()">
            here </a>
          to reset your password.
        </p>
      </div>
    </form>
  <mpms-forgot-password *ngIf="isShowForgotPasswordForm" (showLoginForm)="showLoginForm($event)"></mpms-forgot-password>
<mpms-otp *ngIf="isShowOtpForm" [token]="token"></mpms-otp>
  </div>
</div>