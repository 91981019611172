// import { GoogleAnalyticsService } from './../services/google-analytics.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthFacadeService } from '../auth/facades/auth-facade.service';
import { Logger } from '../services/logger.service';
import { RollbarService } from '.././core.module';
import Swal from 'sweetalert2';

const log = new Logger('ErrorHandlerInterceptor');

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
  
    return next 
      .handle(request)
      .pipe(catchError((error: any) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
  
    if(response) {
      this.showToastError(response);
    }
    log.error('Request error', response);
    throw response;
  }

  private showToastError(error: any): void {
    Swal.fire(error, 'Error!', 'error');
    const rollbar = this.injector.get(RollbarService);
    // rollbar.error(new Error(error?.message).stack);
  }
}
