import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { AuthFacadeService } from '../auth/facades/auth-facade.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
      private authService: AuthFacadeService,
      private router: Router,
      private readonly cookieService:CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    const user  = localStorage.getItem(AppConstants.LS_USER_KEY);
    const adminUser = user ? JSON.parse(user).adminUser : '';
   
    
    
    if (this.authService.getAuthToken() && !request.url.includes('amazonaws')) {
      
        request = request.clone({
            headers: request.url.includes('upload/move2s3') ?
            this.headersForUpload(request, adminUser) :  this.getDefaultHeadersInitially(request, adminUser)
        });
    }else{
     
      request = request.clone({
        headers: this.getDefaultHeadersInitially(request, adminUser)
    });
    }
    return next.handle(request);
    }

    headersForUpload(request, adminUser): HttpHeaders {
      return request.headers.set(
        'Authorization',
        `Bearer ${this.authService.getAuthToken()}`
    )
    .set('x-user-email', adminUser.email).set('x-token', this.authService.getAuthToken()).set('device', '1')
    .set('x-component', 'ADMIN').set('x-ip', this.cookieService.get('ip')).set('x-correlation-id', this.authService.getUID()).set('x-user-id', adminUser?.userId?adminUser?.userId.toString():"");
  }

getDefaultHeadersInitially(request, adminUser): HttpHeaders {
  const leadId = localStorage.getItem(AppConstants.LS_LEAD_ID_KEY);

 if(this.authService.getAuthToken()){
  
  return request.headers.set('Content-Type', 'application/json; charset=utf-8')
.set('x-token', this.authService.getAuthToken()).set('device', '1')
.set('x-page-url', window.location.href)
.set('x-component', 'ADMIN')
.set('x-ip', this.cookieService.get('ip'))

.set('x-correlation-id', this.authService.getUID())
.set('x-user-id', adminUser?.userId?adminUser?.userId.toString():"")
.set('x-lead', leadId?leadId:"");
}
else{
  
  return request.headers.set('Content-Type', 'application/json; charset=utf-8')
.set('x-page-url', window.location.href)
.set('x-component', 'ADMIN')
.set('x-component', this.cookieService.get('ip'))
.set('x-correlation-id', this.authService.getUID())
.set('x-user-id', '1036');
}
}
}
