import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';

@Component({
  selector: 'mpms-assign-supervisor',
  templateUrl: './assign-supervisor.component.html',
  styleUrls: ['./assign-supervisor.component.scss']
})
export class AssignSupervisorComponent implements OnInit {

  leadData: any;
  eventAdd:any;
  supervisors = [];
  isShowErrorMsg:boolean=false;
  superVisorForm: FormGroup;


  constructor(
    public readonly dialogRef: MatDialogRef<AssignSupervisorComponent>,
    private readonly fb: FormBuilder,
    private readonly scheduleService: SurgeryScheduleService

  ) { 


  }
  

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onSelectSupervisor(id): void {
    this.superVisorForm.patchValue({
      supervisor: id
    });
  }

  initForm(schedule): void {


   if(this.eventAdd=="SurgerySupervisor"){
    this.superVisorForm = this.fb.group({
      event: [this.eventAdd],
      lead: [this.leadData?.lsLead],
      presenceType: [schedule.surgPresenceType ?schedule?.surgPresenceType : '',Validators.required],
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY))
        .adminUser.email],
      supervisor: [schedule.surgSupervisor ? schedule?.surgSupervisor : '']
    });
  }else{
    this.superVisorForm = this.fb.group({
      event: [this.eventAdd],
      lead: [this.leadData?.lsLead],
      presenceType: [schedule.presenceType ?schedule?.presenceType : '',Validators.required],
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY))
        .adminUser.email],
      supervisor: [schedule.supervisor ? schedule?.supervisor : '']
    });

  }
  }

  saveSuperVisor(): void {
    this.isShowErrorMsg=true;
    if (this.superVisorForm.invalid) {
      return;
    }
    this.scheduleService.saveSupervisor(this.superVisorForm.getRawValue()).subscribe(resp => {
      if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
        Swal.fire('Success', 'Assigned supervisor successfully', 'success');
        setTimeout(()=>{
        location.reload();
      },1000)

        this.dialogRef.close()
      }
    });
  }

  ngOnInit(): void {
      this.scheduleService.getSupervisorList().subscribe(resp => {
      const supervisors = resp;
      this.supervisors = supervisors;
      this.initForm(this.leadData);
    });

  }

}
