<mat-card>
  <mat-card-header>
    <mat-card-title>Add Surgery Schedule Dates</mat-card-title>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content class="padding-4 customMatFields mat-content">
    <form [formGroup]="scheduleForm" *ngIf="scheduleForm">
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Treatment Type</span>
        </div>
        <div class="col-md-7">
         
          <mat-select formControlName="treatmentTypeArray" multiple>
            <mat-option [value]="treatment.name" *ngFor="let treatment of treatmentList">{{treatment.name}}</mat-option>
          
        </mat-select>


          <!-- <div class="col-md-12">
            <br>
            <input type="text" #treatmentType formControlName = "otherTreatment" (input)="setTreatmentType(treatmentType.value)">
          </div> -->
        </div>
      </div>
      
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Other Treatment Type</span>
        </div>
        <div class="col-md-7">
            <input type="text" #treatmentType formControlName = "otherTreatment" (input)="setTreatmentType(treatmentType.value)">
         
        </div>
      </div>

      <div class="row pb-10">
        <div class="col-md-5">
          <span>Anesthesia Type</span>
        </div>
        <div class="col-md-7">
          <mat-select autocomplete="off" formControlName="anesthesiaType">
            <mat-option value="">Please select</mat-option>
            <mat-option value="Local Anesthesia">Local Anesthesia (LA)</mat-option>
            <mat-option value="Spinal Anesthesia">Spinal Anesthesia(SA)</mat-option>
            <mat-option value="General Anesthesia">General Anesthesia (GA)</mat-option>          
          </mat-select>
        </div>
      </div>

      <div class="row pb-10">
        <div class="col-md-5">
          <span>City</span>
        </div>
        <div class="col-md-7">
          <mpms-city-dropdown [selectedCity] = "selectedCity" (emitSelectedCity)="getSelectedCity($event)"></mpms-city-dropdown>
        </div>
      </div>
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Doctor List</span>
        </div>
        <div class="col-md-7">
          <mpms-autocomplete-dropdown [data]="doctors" placeholder="Doctor" [selectedItem]="scheduleForm.get('doctorId').value" bindValue="id" bindLabel="name"
          (onOptionChange)="getHospitals($event)">
          </mpms-autocomplete-dropdown>
          <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('doctorId')"></mpms-input-validation>
        </div>
      </div>
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Hospital List</span>
        </div>
        <div class="col-md-7">
          <mpms-autocomplete-dropdown [data]="hospitals" [selectedItem]="scheduleForm.get('hospitalId').value" placeholder="Hospital" bindValue="id" bindLabel="name"
            (onOptionChange)="selectHospitals($event)">
          </mpms-autocomplete-dropdown>
          <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('hospitalId')"></mpms-input-validation>
           
        </div>
      </div>
      <div class="row pb-10" *ngIf="isShowAssignCoordinator">
        <div class="col-md-5">
          <span>Assign Coordinator</span>
        </div>
        <div class="col-md-7">
          <mpms-autocomplete-dropdown [data]="supervisors" [bindLabel]="'name'" bindValue="userId" [selectedItem]="selectedSupervisor" (onOptionChange)="onSelectSupervisor($event)">
          </mpms-autocomplete-dropdown>
        </div>
      </div>
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Allow Old Admission</span>
        </div>
        <div class="col-md-7">
          <mat-select autocomplete="off" formControlName="allowOldAdmission">
            <mat-option value="">Please select</mat-option>
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row pb-10 ">
        <div class="col-md-5">
          <span>Select Admission Date/Time</span>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-8 startDate minus-mt-25">
              
                <input matInput [matDatepicker]="picker1" autocomplete="off" (click)="picker1.open()" [min]="minDate" formControlName="admissionDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('admissionDate')"></mpms-input-validation>
           
            </div>
            <div class="col-md-4">
              <mat-select autocomplete="off" formControlName="admissionTime">
                <mat-option value="">Please select</mat-option>
                <mat-option *ngFor="let time of timeList" [value]="time">{{time}}</mat-option>
              </mat-select>
              <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('admissionTime')"></mpms-input-validation>
           
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Select Surgery Date/Time</span>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-8 startDate minus-mt-25">
               
                <input matInput [matDatepicker]="picker2" autocomplete="off" (click)="picker2.open()" formControlName="surgeryDate" [min]="scheduleForm.get('admissionDate').value">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('surgeryDate')"></mpms-input-validation>
           
            </div>
            <div class="col-md-4">
              <mat-select autocomplete="off" formControlName="surgeryTime">
                <mat-option value="">Please select</mat-option>
                <mat-option *ngFor="let time of timeList" [value]="time">{{time}}</mat-option>
              </mat-select>
              <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('surgeryTime')"></mpms-input-validation>
           
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Room Category</span>
        </div>
        <div class="col-md-7">
          <mat-select autocomplete="off" formControlName="roomCategory">
            <mat-option value="">Please select</mat-option>
            <mat-option value="General Ward">General Ward</mat-option>
            <mat-option value="Private Ward">Private Ward</mat-option>
            <mat-option value="Semi Private Ward ">Semi Private Ward </mat-option>          
          </mat-select>
        </div>
      </div>
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Engagement Model </span>
        </div>
        <div class="col-md-7">
          <mat-select autocomplete="off" formControlName="engagementModal">
            <mat-option value="">Please select</mat-option>
            <mat-option value="Model A"> Model A</mat-option>
            <mat-option value="Model B">Model B</mat-option>
                    
          </mat-select>
        </div>
      </div>
     
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Surgery Durations Hours/Mins </span>
        </div>
        <div class="col-md-7">
            <!-- <input type="number"  formControlName = "surgeryDuration" > -->
        
              <div class="row">
                <div class="col-md-4">
                   
                  <mat-select autocomplete="off" formControlName="surgeryDuration">
                    <mat-option value="">Please select</mat-option>
                    <mat-option *ngFor="let time of hourList" [value]="time">{{time}}</mat-option>
                  </mat-select>
                </div>
                <div class="col-md-4">
                  <mat-select autocomplete="off" formControlName="surgeryMins">
                    <mat-option value="">Please select</mat-option>
                    <mat-option *ngFor="let time of minList" [value]="time">{{time}}</mat-option>
                  </mat-select>
                  
                </div>
              </div>
            
         
        </div>
      </div>
 <!-- <div class="row pb-10">
        <div class="col-md-5">
          <span>Select Discharge Date/Time</span>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-8 startDate minus-mt-25">
             
                <input matInput [matDatepicker]="picker3" autocomplete="off" (click)="picker3.open()" formControlName="dischargeDate" [min]="scheduleForm.get('admissionDate').value">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('dischargeDate')"></mpms-input-validation>
           
            </div>
            <div class="col-md-4">
              <mat-select autocomplete="off" formControlName = "dischargeTime">
                <mat-option value="">Please select</mat-option>
                <mat-option *ngFor="let time of timeList" [value]="time">{{time}}</mat-option>
              </mat-select>
              <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="scheduleForm.get('dischargeTime')"></mpms-input-validation>
           
            </div>
          </div>
        </div>
      </div> -->
      <div class="row pb-10">
        <div class="col-md-5">
          <span>Cab Requested</span>
        </div>
        <div class="col-md-7">
          <mat-select autocomplete="off" formControlName="cabRequest">
           <mat-option value="">Please select</mat-option>
            <mat-option value="From Hospital to Home">From Hospital to Home</mat-option>
            <mat-option value="From Home to Hospital">From Home to Hospital</mat-option>
            <mat-option value="Both Ways">Both Ways</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row pb-10">
        <div class="col-md-5">
          <span>Hospitalization Days</span>
        </div>
        <div class="col-md-7">
          <input matInput type="number" formControlName="hospitalDuration"/>
        </div>
      </div>

      <div class="row pb-10">
        <div class="col-md-5">
          <span>Implants</span>
        </div>
        <div class="col-md-7">
          <input matInput type="text" formControlName="implants"/>
        </div>
        
      </div>

    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button  (click)="onCloseDialog()"> Close </button>
    <button mat-raised-button (click)="onSubmit()" color="primary">Add  </button>
  </mat-card-actions>
</mat-card>
