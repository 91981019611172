export class Schedule {
    admissionDate: string;
    admissionTime: string;
    allowOldAdmission: string;
    dischargeDate: string;
    dischargeTime: string;
    doctorId: string;
    event: string;
    hospitalId: string;
    lead: string;
    newValue: string;
    posted: string;
    surgeryDate: string;
    surgeryTime: string;
    supervisor: string;
    treatmentType: string;
    treatmentTypeArray:any;
    surgeryHospitalId: string;
    surgeryDocId: string;
    anesthesiaType: string;
    constructor(data?: Partial<Schedule>) {
     
        this.admissionDate = data?.admissionDate;
        this.allowOldAdmission =  data?.allowOldAdmission;
        this.dischargeDate = data?.dischargeDate;
        this.doctorId = data?.doctorId;
        this.event = data?.doctorId;
        this.hospitalId = data?.hospitalId;
        this.lead = data?.lead;
        this.newValue = data?.newValue;
        this.posted = data?.posted;
        this.surgeryDate = data?.surgeryDate;
        this.surgeryTime = data?.surgeryTime;
        this.supervisor = data?.supervisor;
        this.treatmentType = data?.treatmentType;
        this.treatmentTypeArray=data?.treatmentTypeArray;
        this.surgeryHospitalId = data?.surgeryHospitalId;
    }
}
