import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import Swal from 'sweetalert2';
import { AuthFacadeService } from '../core/auth/facades/auth-facade.service';
import { HttpStatusCode } from '../core/enums/http-status-codes.enum';
import { AppConstants } from '../shared/constants/app.constants';

@Component({
  selector: 'mpms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  isShowForgotPasswordForm = false;
  isShowOtpForm = false;
  loginForm: FormGroup;
  isShowErrorMsg: boolean;
  returnUrl: string;
  token:string;
  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthFacadeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if(param.returnUrl) {
        this.returnUrl = param.returnUrl;
      }

    })
    this.initLoginForm();
  }

  initLoginForm(): void {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, CustomValidators.emailValidator]],
      password: [null, [Validators.required]]
    });
  }

  showForgotPasswordForm(): void {
    this.isShowForgotPasswordForm = true;
    this.isShowOtpForm = false;
  }

  showLoginForm(isShowLoginForm: Event): void {
    if (isShowLoginForm) {
      this.isShowForgotPasswordForm = false;
      this.isShowOtpForm = false;
    }
  }

  getPermission(userData) {
    localStorage.setItem(AppConstants.LS_PERMISSION_KEY, userData.map(item => item.permissionList).flat().map(a => a.permissionTag))
  }

  onLogin(): void {
    this.isShowErrorMsg = true;
    if (this.loginForm.invalid) {
      return;
    }
    const loginPayload = this.loginForm.getRawValue();
    this.authService.login(loginPayload).subscribe(response => {
      if (response.statusCode === HttpStatusCode.ACCESS_DENIED) {
        Swal.fire(response?.message?.messageDesc,
        'Error!',
        'error'
        );
      } else if (response.statusCode === HttpStatusCode.SUCCESSFUL) {

        this.isShowOtpForm = true;
        this.token=response.adminUser;
      
      }
    });
  }
}
