import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand, PostAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class PaymentHistory<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, mobile: string) {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/payment/customer-transactions/${mobile}`);

    }
}
export class PaymentStatus<T> extends PostAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/payment/transaction-current-status`);

    }
}
