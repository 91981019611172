import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class TASKSCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, leadId: string, startIndex: number,
                count: number, startDate?: string | null, endDate?: string | null,task?:string) {
                    if (startDate && endDate) {
                       
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/report/task-info?lead=${leadId}&rowIndex=${startIndex}&rowCount=${count}&fromDate=${startDate}&toDate=${endDate}&type=${task}`);
                    } else {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/report/task-info?lead=${leadId}&rowIndex=${startIndex}&rowCount=${count}&fromDate=&toDate=&type=${task}`);
                    }
    }
}
