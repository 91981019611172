import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnyAdapter } from 'src/app/core/api/adapters/any-adapter.service';
import { ApiService } from 'src/app/core/api/api.service';
import { CityAdapter } from '../adapters/city.adapter.service';
import { GetCitiesCommand } from '../commands/get-city.command';
import { City } from '../models/city.model';

@Injectable()
export class CityFacadeService {

  constructor(
    private readonly apiService: ApiService,
    private readonly cityAdapter: CityAdapter,
    private readonly anyAdapter: AnyAdapter
  ) { }

  getCities(email: string): Observable<City[]> {
    const command = new GetCitiesCommand(this.apiService, this.cityAdapter, email);
    return command.execute();
  }
}
