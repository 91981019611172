
    <form class="forget-form" [formGroup]="otpForm">
        <h3 class="">OTP</h3>
        <p>Enter otp sent to registered mobile number. Contact system admin in case of any issues.</p>
        <div class="form-group">
          <input class="form-control placeholder-no-fix" type="number" formControlName="otp"  placeholder="otp"/>
          <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="otpForm.get('otp')"></mpms-input-validation>
        </div>
        <div class="form-actions">
            
          <button mat-raised-button color="primary" class="pull-right" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </form>