import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mpms-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  fromDate = new Date();
  toDate = new Date();
  @Output() emitFilter = new EventEmitter();
  constructor(
    private readonly datePipe: DatePipe
  ) { }


  onApplyFilter(): void  {
    if (!this.fromDate || !this.toDate) {
      return;
    }
    this.emitFilter.emit({
      toDate: this.datePipe.transform(this.toDate, 'yyyy-MM-dd'),
      fromDate: this.datePipe.transform(this.fromDate, 'yyyy-MM-dd')
    });

  }

  ngOnInit(): void {
  }

}
