<mat-card>
    <mat-card-header>
      <mat-card-title>Update Lead Source</mat-card-title>
      <span class="spacer"></span>
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content class="padding-4 customMatFields">
      <form [formGroup]="updateSourceForm" *ngIf="updateSourceForm">
        <div class="row">
          <div class="col-md-4">
            <mat-label>Source</mat-label>
            <mpms-autocomplete-dropdown 
              [data]="sourceData" 
              [bindLabel]="'name'" 
              groupBy="parentSource" 
              bindValue="id"
              [selectedItem]="updateSourceForm.get('source').value" 
              (onOptionChange)="updateSourceForm.get('source').setValue($event)">
            </mpms-autocomplete-dropdown>
          </div>
  
          <div class="col-md-4">
            <mat-label>Comments</mat-label>
            <textarea matInput type="text" formControlName="comments"></textarea>
           
            <mpms-input-validation [isShowErrorMsg]="isShowErrorMsg" [control]="updateSourceForm.get('comments')"></mpms-input-validation>
      
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-raised-button class="mr-20" (click)="onCloseDialog()">Close</button>
      <button mat-raised-button (click)="saveLeadOwner()" color="primary">Add</button>
    </mat-card-actions>
  </mat-card>
  