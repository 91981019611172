import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class GetSourceNameCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/user/source-list/v2`);
    }
}
