import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class ChatHistory<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, mobile: string) {
                        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/chat/history/mobile/${mobile}?startindex=0&count=20&startDate=&endDate=`);
                  
    }
}

