import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthFacadeService } from '../core/auth/facades/auth-facade.service';
import { HttpStatusCode } from '../core/enums/http-status-codes.enum';
import { AppConstants } from '../shared/constants/app.constants';

@Component({
  selector: 'mpms-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  @Output() showLoginForm = new EventEmitter(false);

  otpForm: FormGroup;
  isShowErrorMsg = false;
  returnUrl:string;
  @Input() token: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthFacadeService)
    { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if(param.returnUrl) {
        this.returnUrl = param.returnUrl;
      }

    })
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm(): void {
    this.otpForm = this.fb.group({
      otp: [null , [Validators.required, Validators.maxLength(6)]],
      eventType: ['reset-password']
    });
  }

  onShowLoginForm(): void {
    this.showLoginForm.emit(true);
  }

  onSubmit(): void {
    this.isShowErrorMsg = true;
    if (this.otpForm.invalid) {
      return;
    }
    let loginPayload = {
      "token":this.token.token,
      "email":this.token.email,
      "otp":this.otpForm.getRawValue().otp
    }
    console.log(loginPayload);
    this.authService.login(loginPayload).subscribe(response => {
      if (response.statusCode === HttpStatusCode.ACCESS_DENIED) {
        Swal.fire(response?.message?.messageDesc,
        'Error!',
        'error'
        );
      } else if (response.statusCode === HttpStatusCode.SUCCESSFUL) {

        Swal.fire(response?.message?.messageDesc,
        'Success!',
        'success'
        ).then(() => {
          this.authService.storeDataToStorage(response, 'login');
          if(localStorage.getItem(AppConstants.LS_LEAD_ID_KEY)==null){
            this.router.navigateByUrl(this.returnUrl);
          }else{
          window.location.href="/lead/details/"+localStorage.getItem(AppConstants.LS_LEAD_ID_KEY);
        }
      });
      }
    });

  }


}
