import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'mpms-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() dataCount: number;
  limit = 20;
  pageNumber: number;
  transformPageNumber: number
  startIndex = 20;
  @Output() emitPaginationEvent = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() { }


  ngOnInit(): void {
  }

  goToPage(): void {
    this.transformPageNumber = (this.pageNumber<=1)?0:this.pageNumber-1
    this.paginator.pageIndex = this.transformPageNumber, // number of the page you want to jump.
      this.paginator.page.next({
        pageIndex: this.transformPageNumber,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
  }

  handlePagination(event: PageEvent): void {
    this.emitPaginationEvent.emit(this.startIndex * event.pageIndex);
  }
}
