import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { ApiModule } from './api/api.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import {ErrorInterceptor} from './interceptors/auth-interceptor.service'
import * as Rollbar from 'rollbar';
import { AppConstants } from '../shared/constants/app.constants';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoggedInGuardService } from './guards/loggedIn-guard.service';
import { LeadDetailGuardService } from './guards/lead-detail-guard.service';



const rollbarConfig = {
  accessToken: AppConstants.ROLLBAR_TOKEN_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
 };

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    ApiModule,
    HttpClientModule,
  ],
  providers: [
   {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory
    },
    AuthGuardService,
    LoggedInGuardService,
    LeadDetailGuardService,
    
  ],
  exports: [AuthModule, ApiModule]
})
export class CoreModule { }
