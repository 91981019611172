import {
  Component,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { LeadDetailFacadeService } from 'src/app/main/lead/facades/lead-detail-facade.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppConstants } from '../../constants/app.constants';

@Component({
  selector: 'mpms-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  dynamicRequestUrl: string;
  dynamicForm: FormGroup;
  formTitle: string;
  formData: any[];
  showErrorMsg = false;
  leadId: string;
  constructor(
    private readonly leadDetailService: LeadDetailFacadeService,
    protected readonly dialogRef: MatDialogRef<
    DynamicFormComponent
    >
  ) {}

  ngOnInit(): void {

    setTimeout(() => {
      //code for your new value.
   

    this.leadDetailService
      .getDynamicContent(this.dynamicRequestUrl).pipe(take(1))
      .subscribe((content) => {
        console.log("-------------------------------");
        console.log(content);
        this.formTitle = content.title;
        this.formData = content.components;
      
        this.formData.find(x=> {
          if (x.key == "surgeryAdvice") {
            x.preSelected = x.preSelected.split('|');
        
          }
        });
    
        const elementPerRow = 3;
        const rowCount = Math.ceil(content.components.length / elementPerRow);
        let j = 0;
        this.dynamicForm = new FormGroup({});
        for (let index = 0; index < rowCount; index++) {
          for (; j < index * elementPerRow + elementPerRow; j++) {
            if (j < content.components.length) {
              this.dynamicForm.addControl(
                content.components[j].key,
                new FormControl(
                    content.components[j].preSelected ?
                     content.components[j].preSelected : '', content.components[j].mandatory ? Validators.required : null)
              );
            } else {
              break;
            }
          }
        }
      
      });
    },0);
  }

  onCloseDialog(): void {
      this.dialogRef.close();
  }
  surgeryAdvice:any
  onSubmit(): void {
    this.showErrorMsg = true;
    const user  = localStorage.getItem(AppConstants.LS_USER_KEY);
    if (this.dynamicForm.valid) {
        const adminUser = user ? JSON.parse(user).adminUser : '';
        this.dynamicForm.addControl('event', new FormControl(this.formTitle));
        this.dynamicForm.addControl('lead', new FormControl(this.leadId));
        this.dynamicForm.addControl('postedBy', new FormControl(adminUser.email));
        let payload = { ...this.dynamicForm.getRawValue() };
        if(this.formTitle=='Update Medical Info'){
        const changeByPipe=this.dynamicForm.get('surgeryAdvice').value.join(" | ");
        this.dynamicForm.get('surgeryAdvice');
      
        payload.surgeryAdvice = changeByPipe;        
      }
      if(this.formTitle=='F2F Completed'){
        const changeByPipe=this.dynamicForm.get('surgeryAdvice').value.join(" | ");
        this.dynamicForm.get('surgeryAdvice');
      
        payload.surgeryAdvice = changeByPipe;  
        payload['surgeryAdvice']=payload['surgeryAdvice'].toString();       
      }
     
        console.log(payload);


        this.leadDetailService.saveDynamicForm(payload).subscribe(resp => {
          if(resp.statusCode==412){           
            Swal.fire('Error',resp.message,'error').then(() => {
              this.dialogRef.close();
          });
          return
          }
            Swal.fire('',
            'Success!',
            'success'
            ).then(() => {
                this.dialogRef.close();
                location.reload();
            });

        });
    }
  }
}
