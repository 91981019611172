import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand, PostAPICommand} from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class DownlodableDocuments<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, leadId: string) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/export-documents/${leadId}`);
    }
}
export class DownloadDocument<T> extends PostAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, leadId: string) {
                   super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/lead/export-documents/${leadId}`);
    }
}
