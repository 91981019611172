import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';
import { Schedule } from '../../models/schedule.model';
import * as _ from 'underscore'; 
import { ActionFacadeService } from 'src/app/main/lead/facades/action-facade.service';

@Component({
  selector: 'mpms-surgery-schedule',
  templateUrl: './surgery-schedule.component.html',
  styleUrls: ['./surgery-schedule.component.scss']
})
export class SurgeryScheduleComponent implements OnInit {
  leadId: string;
  doctors = [];
  hospitals = [];
  supervisors = [];
  scheduleForm: FormGroup;
  isShowErrorMsg = false;
  timeList = AppConstants.APPOINTMENT_FILTER_TIME;
  minList=[10,20,30,40,50];
  hourList=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  minDate = new Date();
  schedule: Schedule;
  selectedSupervisor: number;
  isShowAssignCoordinator = true;
  action: string = "";
  anesthesiaType:string="";
 
  treatment = '';
  referrals = [];
  serviceId: number;
  laiRoomType:string;
  engagementModal:string;
  surgeryDuration:string;
  surgeryMins:string;
  hospitalDuration:number;
  implants:string;
  treatmentList = [];
  selectedCity: any;
  @ViewChild('treatmentType', { static: false }) treatmentType : ElementRef;
  @ViewChild('treatmentTypeArray', { static: false }) treatmentTypeArray : ElementRef;

  constructor(
    public readonly dialogRef: MatDialogRef<SurgeryScheduleComponent>,
    private readonly surgeryScheduleService: SurgeryScheduleService,
    private readonly fb: FormBuilder,
    private readonly actionService: ActionFacadeService,
    private readonly datePipe: DatePipe

  ) { }

  getDoctors(): void {
    this.surgeryScheduleService.getDoctors(localStorage.getItem(AppConstants.NUMERIC_LEAD_ID_KEY)).subscribe(resp => {
      this.doctors = resp;
    });
  }

  getHospitals(docId): void {
    if(docId!='-1'){
    this.scheduleForm.patchValue({
      doctorId: docId
    })}
    this.surgeryScheduleService.getHospitals(docId).subscribe(hospitals => {
      this.hospitals = hospitals;
    });
  }

  selectHospitals(hosId) {
    this.scheduleForm.patchValue({
      hospitalId: hosId
    })
  }

  getSuperVisors(): void {
    this.surgeryScheduleService.getSupervisorList().subscribe(sv => {
      this.supervisors = sv;
    });
  }


  initScheduleForm(): void {
    console.log(this.schedule);
    this.scheduleForm = this.fb.group({
      admissionDate: [this.schedule.admissionDate ? this.schedule.admissionDate : '',[Validators.required] ],
      allowOldAdmission: ['No'],
      dischargeDate: [this.schedule.dischargeDate ? this.schedule.dischargeDate : ''],
      doctorId: [(this.schedule.surgeryDocId!='-1' && this.schedule.surgeryDocId)  ? this.schedule.surgeryDocId : '',[Validators.required]],
      event: [this.action==='Direct Surgery'?'Direct Surgery':'Surg Sched'],
      hospitalId: [(this.schedule.surgeryHospitalId!='-1' && this.schedule.surgeryHospitalId) ? this.schedule.surgeryHospitalId : '',[Validators.required]],
      lead: [this.leadId],
      newValue: [this.action],
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY))
        .adminUser.email],
      surgeryDate: [this.schedule.surgeryDate ? this.schedule.surgeryDate : '',[Validators.required]],
      surgeryTime: [this.schedule.surgeryTime ? this.schedule.surgeryTime : '',[Validators.required]],
      supervisor: [this.schedule.supervisor ? this.selectedSupervisor = +this.schedule.supervisor : ''],
      treatmentTypeArray: [this.schedule.treatmentTypeArray ? this.schedule.treatmentTypeArray : ''],
      admissionTime: [this.schedule.admissionTime ? this.schedule.admissionTime : '',[Validators.required]],
      dischargeTime: [this.schedule.dischargeTime ? this.schedule.dischargeTime : ''],
      otherTreatment: [''],
      cabRequest:[''],
      anesthesiaType:[this.anesthesiaType],
      roomCategory:[this.laiRoomType],
      engagementModal:[this.engagementModal],      
      surgeryDuration:[this.surgeryDuration],
      surgeryMins:[this.surgeryMins],
      hospitalDuration:[this.hospitalDuration],
      implants:[this.implants],

      city: [this.selectedCity]
    });

    this.getHospitals(this.scheduleForm.get('doctorId').value==""?'-1':this.scheduleForm.get('doctorId').value);
    
  }

  // addTreatmentType() {
  //   let value ;
  //   if(this.treatmentList.includes(this.schedule.treatmentType)) {
  //     value = this.schedule.treatmentType;
  //   } else {
  //     value = 'others'
  //   }

  //   return value

  // }

  ngOnInit(): void {
   
    this.getDoctors();
    this.getSuperVisors();
    this.initScheduleForm();
    this.getTreatmentTypeList();
      this.scheduleForm.get('allowOldAdmission').valueChanges.subscribe(value => {
        if(value === 'Yes') {
          this.minDate = null;
        } else{
          this.minDate = new Date();
        }
      });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onSelectTreatmentType(event) {
    console.log(event)
  }

  
  getSelectedCity(name) {
    this.scheduleForm.patchValue({
      city: name
    });
    this.getReferalDoctor(name);
  }

  getReferalDoctor(city) {
    this.actionService.getReferalDoctor(city).subscribe(resp => {
      this.doctors = resp.data;
    })
  }

  onSelectReferralDoctor(id) {
    this.scheduleForm.patchValue({
      referral: id
    })
  }

  getTreatmentTypeList() {
    this.surgeryScheduleService.getTreatmentTypeList(this.serviceId).subscribe(resp => {
      this.treatmentList = resp.map(treatment => treatment);
      
      const treatment = this.scheduleForm.getRawValue();
  
      var ar = [];
      var treatmentType = treatment.treatmentTypeArray
      for (let key in treatmentType) {
          ar.push({ name: treatmentType[key] })
      }
      var surgeryTypeList =  this.treatmentList.concat(ar)
       this.treatmentList = _.uniq(surgeryTypeList, 'name');
      console.log(  this.treatmentList)
      // if(this.schedule.treatmentType && !this.treatmentList.includes(this.schedule.treatmentType)) {
      //   this.scheduleForm.patchValue({
      //     treatmentType: 'others',
      //     otherTreatment: this.schedule.treatmentType
      //   })
      // }
    })
  }

  onSubmit(): void {
    const data = this.scheduleForm.getRawValue();  
   
      // Calculate total duration in minutes
      data.surgeryDuration = (data.surgeryDuration * 60) + data.surgeryMins;

      // Remove surgeryMins from the form value
      delete data.surgeryMins;
      console.log(data)
    this.isShowErrorMsg = true;
    console.log(data.dischargeDate )
    if(data.dischargeDate == 'null'){
      data.dischargeDate="";
    }
     
    console.log(data);
  
   

    if(data.treatmentTypeArray==undefined || data.treatmentTypeArray==""){     
      data.treatmentTypeArray=new Array();
      data.treatmentTypeArray.push(data.otherTreatment)   
    }else{
      data.treatmentTypeArray.push(data.otherTreatment)   
    }
    delete data["otherTreatment"];
  //   if(data.allowOldAdmission=="No"){
  //   Swal.fire('Error','Please select allow admission Yes','error')
  //   return
  // }
    if(this.scheduleForm.valid){
   
    if(this.action=="Direct Surgery")
    {
      const {admissionTime, dischargeTime, surgeryTime, ...payload} = data;
      payload.admissionDate = `${new DatePipe('en').transform(data.admissionDate, 'yyyy-MM-dd')} ${admissionTime}`;
      payload.dischargeDate = `${new DatePipe('en').transform(data.dischargeDate, 'yyyy-MM-dd')} ${dischargeTime}`;
      
      payload['reminderDateTime'] = `${new DatePipe('en').transform(data.surgeryDate, 'yyyy-MM-dd')} ${data.surgeryTime}`;
      delete payload.surgeryDate;
      console.log(payload)
      this.surgeryScheduleService.updateLeadDirectSurgery(payload).subscribe(resp => {
        console.log('resp--direct surgery')
        console.log(resp)
        if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
          console.log('success--direct surgery')
          Swal.fire('Success', 'Schedule added successfully', 'success');
          this.dialogRef.close();
  
        } else if(resp.statusCode === HttpStatusCode.ACCESS_DENIED) {
          Swal.fire('Error', resp.message, 'error');
          this.dialogRef.close();
        }
      });
    }
    else{
      console.log(data)
      this.surgeryScheduleService.addSurgerySchedule(data).subscribe(resp => {
        console.log('resp-- non direct surgery')
        console.log(resp)
        if (resp.statusCode === HttpStatusCode.SUCCESSFUL) {
          console.log('success-- non direct surgery')
          Swal.fire('Success', 'Schedule added successfully', 'success');
          this.dialogRef.close();
  
        } else if(resp.statusCode === HttpStatusCode.ACCESS_DENIED) {
          Swal.fire('Error', resp.message, 'error');
          this.dialogRef.close();
        }
      });
  
  
  
    }
  }
    

 }

 setTreatmentType(value) {
   this.treatment = value;
 }

 onChangeAdmission(e): void {
  console.log(e.value);
 }
  onSelectSupervisor(id): void {
    this.scheduleForm.patchValue({
      supervisor: id
    });
  }

}
