

import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';


export class GetScheduleSlotCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>,data:any) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/calendar/doctor/slot/v4?doctor=${data?.doctor}&hospital=${data.hospital}&fromDate=${data.fromDate}&toDate=${data.toDate}&appointmentType=${data.appointmentType}`);
    }
}


