import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AnyAdapter } from "src/app/core/api/adapters/any-adapter.service";
import { ApiService } from "src/app/core/api/api.service";
import { GetSurgeryScheduleCommand } from "src/app/shared/commands/get-surgery-schedule.command";
import { AddCommentsCommand } from "../commands/add-comments.command";
import { CreateLeadCommand } from "../commands/create-lead.command";
import { CreatePaymentLinkCommand } from "../commands/create-payment-link.command";
import { GetDoctorReferralCommand } from "../commands/get-doctor-referral.command";
import { getF2FSMSCommand } from "../commands/get-f2f-sms.command";
import { GetScheduleSlotCommand } from "../commands/get-schedule-slot.command";
import { SaveNotifyOpsCommand } from "../commands/save-notify-ops.command";
import { UpdateLeadNameCommand } from "../commands/update-lead-name.command";
import { UpdatePaymentCommand } from "../commands/update-payment.command";


@Injectable()
export class ActionFacadeService {
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
  ) { }

  onSaveNotifyOp(data):Observable<any> {
    const command = new SaveNotifyOpsCommand(this.apiService, this.anyAdapter);
    command.parameters= {
      data
    };
    command.showLinearLoader = true;
    return command.execute();
  }

  onAddComments(data): Observable<any> {
    const command = new AddCommentsCommand(this.apiService, this.anyAdapter);
    command.parameters= {
      data
    };
    command.showLinearLoader = true;
    return command.execute(); 
  }

  onUpdatePayment(data): Observable<any> {
    const command = new UpdatePaymentCommand(this.apiService, this.anyAdapter);
    command.parameters= {
      data
    };
    command.showLinearLoader = true;
    return command.execute(); 
  }

  getMsg(leadId) {
    const command = new getF2FSMSCommand(this.apiService, this.anyAdapter, leadId);
    command.showLinearLoader = true;
    return command.execute(); 
  }

  onCreatePaymentLink(data) {
    const command = new CreatePaymentLinkCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data
    }
    command.showLinearLoader = true;
    return command.execute(); 
  }

  getReferalDoctor(city): Observable<any> {
    const command = new GetDoctorReferralCommand(this.apiService, this.anyAdapter,city);
    command.showLinearLoader = true;
    return command.execute(); 
  }

  updateLeadName(data): Observable<any> {
    const command = new UpdateLeadNameCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data
    }
    command.showLinearLoader = true;
    return command.execute();
  }
  CreateLead(data): Observable<any> {
    const command = new CreateLeadCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data
    }
    command.showLinearLoader = true;
    return command.execute();
  }

  

  getScheduledSolots(data) {
    const command = new GetScheduleSlotCommand(this.apiService,this.anyAdapter,data);
    command.showLinearLoader = true;
    return command.execute();
  }



  

}