

<mat-card>
        <mat-card-header>
          <mat-card-title>Add or edit Tags</mat-card-title>
          <span class="spacer"></span>
          <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="padding-4 customMatFields">
          <form [formGroup]="associateTagsForm">
            <div class="row">
              <div  style="margin-top: 4%;">
                    <mat-label> Associated Tags </mat-label>
                    <!-- <mat-select formControlName="tags" *ngIf="masterTags.length>0" (selectionChange)="changeTags($event)" multiple>
                      <mat-option *ngFor="let tag of masterTags" [value]="tag.tagId">{{tag.tagName }}
                      </mat-option>
                    </mat-select> -->
                    <!-- <mat-select formControlName="tags" multiple >

                      <mat-form-field>
                        <input matInput id="multiple" placeholer="Search"  (input)="filterOptions($event)" />
                      </mat-form-field>
                    
                      <mat-option *ngFor="let option of masterTags" [value]="option.tagId">
                        {{option.tagName}}
                      </mat-option>
                    
                    
                    </mat-select>  -->

                    <mat-select (openedChange)="openedChange($event)" placeholder="Select" [formControl]="tags" multiple>
                     
                      <div class="select-container">
                      <mat-optgroup >
                    <mat-form-field style="width:100%;">
                      <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchTextboxControl">
                      <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
                      <mat-icon >close</mat-icon>
                    </button>
                           </mat-form-field>
                      </mat-optgroup>
                      <mat-optgroup *ngIf="(filteredOptions | async).length == 0">
                        <div>No results found!</div>
                      </mat-optgroup>
                   <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option.tagId">
                          {{option.tagName}}
                        </mat-option>
                  </div>
                  </mat-select>
                   
                
                 
                  
                   
                </div>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions align="end">
          <button mat-raised-button class="mr-20" (click)="onCloseDialog()"> Close </button>
          <button mat-raised-button (click)="saveLeadOwner()" color="primary">Add </button>
        </mat-card-actions>
      </mat-card>
