export enum HttpStatusCode {
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
  TEMPORARY_REDIRECT = 307,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  METHOD_FAILURE = 420,
  UNKNOWN_ERROR_CATEGORY = 'UNKNOWN_ERROR',
  SUCCESSFUL = 200,
  ACCESS_DENIED = 412,
  SERVER_MESSAGE = 417
}
