import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthFacadeService } from "../auth/facades/auth-facade.service";


@Injectable()
export class LoggedInGuardService implements CanActivate {
  constructor(
    private readonly auth: AuthFacadeService,
    private readonly router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.getAuthToken()) {
      this.router.navigateByUrl('');
      return false;
    } else {
        return true;
    }
  }
}