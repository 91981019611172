import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { PutAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';

export class UpdateTasks<T> extends PutAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>,taskId) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/report/task-info?task=${taskId}`);
    }
   
}


