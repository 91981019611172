import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnyAdapter } from 'src/app/core/api/adapters/any-adapter.service';
import { ApiService } from 'src/app/core/api/api.service';
import { GetDoctorsCommand } from '../commands/get-doctors.command';
import { GetGroupAttributeCommand } from '../commands/get-group-attribute.command';
import { GetSMSDoctorsCommand } from '../commands/get-sms-doctors.command';
import { Hospitals } from '../models/hospital.model';

@Injectable()
export class DoctorFacadeService {
  hospitals$: BehaviorSubject<any> = new BehaviorSubject([]);


  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter
  ) { }

  getDoctors(city: string): Observable<any> {
    const command =  new GetDoctorsCommand(this.apiService, this.anyAdapter, city);
    return command.execute().pipe(map(resp => resp.data[0]));
  }
  getSMSDoctors(city: string): Observable<any> {
    const command =  new GetSMSDoctorsCommand(this.apiService, this.anyAdapter, city);
    return command.execute().pipe(map(resp => resp.data[0]));
  }
  getAttributeTypesByServiceId(city: string,id:string): Observable<any> {
    const command =  new GetGroupAttributeCommand(this.apiService, this.anyAdapter, city,id);
    return command.execute().pipe(map(resp => resp.data));
  }

  setHospitals(hospitals: Hospitals[]): void {
    this.hospitals$.next(hospitals);
  }
}
