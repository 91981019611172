import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';

export class GetSMSDoctorsCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, city: string) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/location/city-doctor-hospital-list?city=${city}`);
    }
}
