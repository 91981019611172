import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { HttpStatusCode } from 'src/app/core/enums/http-status-codes.enum';
import { ActionFacadeService } from 'src/app/main/lead/facades/action-facade.service';
import Swal from 'sweetalert2';
import { AppConstants } from '../../constants/app.constants';
import { SurgeryScheduleService } from '../../facades/surgery-schedule.service';

@Component({
  selector: 'mpms-backdated-appointments',
  templateUrl: './backdated-appointments.component.html',
  styleUrls: ['./backdated-appointments.component.scss']
})
export class BackdatedAppointmentsComponent implements OnInit {
  leadId: string;
  doctors = [];
  hospitals = [];
  backupDateForm: FormGroup;
  timeSlotForm: FormGroup;
  minFromDate = new Date();
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  showSlots = false;
  slotList = [];
  selectSlot = false;
  adressType = 'geocode';
  data: any;
  @ViewChild('addresstext') addresstext: ElementRef;

  autocompleteInput: string;
  queryWait: boolean;

  
  constructor(
    public readonly dialogRef: MatDialogRef<BackdatedAppointmentsComponent>,
    private readonly fb: FormBuilder,
    private readonly surgeryScheduleService: SurgeryScheduleService,
    private readonly actionService: ActionFacadeService,
    private readonly mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone

  ) { }



private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
            componentRestrictions: { country: 'IN' },
            types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
        });
    autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          let place : google.maps.places.PlaceResult = autocomplete.getPlace();
          this.timeSlotForm.patchValue({
            locality: place.formatted_address,
            latlong: `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`
          })
          if(place.geometry == undefined || place.geometry === null) {
            return
          }
        })
    });
}


  getDoctors(): void {
    this.surgeryScheduleService.getDoctors(localStorage.getItem(AppConstants.NUMERIC_LEAD_ID_KEY)).subscribe(resp => {
      this.doctors = resp;
    });
  }

  getHospitals(docId): void {
    this.backupDateForm.patchValue({
      doctor: docId
    })
    this.surgeryScheduleService.getHospitals(docId).subscribe(hospitals => {
      this.hospitals = hospitals;
    });
  }

  selectHospitals(hosId) {
    this.backupDateForm.patchValue({
      hospital: hosId
    })
  }

  getSlotes() {
    const data =  this.backupDateForm.getRawValue();
    data.fromDate = data.fromDate ? `${new DatePipe('en').transform(data.fromDate, 'yyyy-MM-dd')}` : undefined;
    data.toDate = data.toDate? `${new DatePipe('en').transform(data.toDate, 'yyyy-MM-dd')}` : undefined;

    this.actionService.getScheduledSolots(data).subscribe(resp => {
      this.slotList = resp.slotList;
      if(this.slotList?.length) {
        this.showSlots = true;
      } else {
        Swal.fire('No Slots Found',
          'Error!',
          'error'
          );
      }
    }) 
  }

  onSelectFromDate(event) {
    this.backupDateForm.patchValue({
      toDate: new Date(event.value)
    })
  }


  clearLookUpCal(){
    this.showSlots = false;
    this.timeSlotForm.reset();
  }

  onCloseDialog() {
    this.dialogRef.close()
  }

  markLookup(id, date, slotTime) {
    this.selectSlot = id;
    this.timeSlotForm.patchValue({
      slotId: id,
      slot: slotTime,
      scheduleDate: date
    });
  }



  onSubmit() {
    var data=this.timeSlotForm.getRawValue();
    if(data.slot==null || data.slot==""){
      Swal.fire(
        'Error!',
       'Please Select a slots',
        'error'
      )
      return 
    }
   
    this.surgeryScheduleService.saveSupervisor(this.timeSlotForm.getRawValue()).subscribe(resp => {
      if(resp.statusCode === HttpStatusCode.ACCESS_DENIED) {
        Swal.fire(
          'Error!',
          resp.message,
          'error'
        )
      } else {
        Swal.fire(
          'Success!',
          resp.message,
          'success'
        )
      }
      this.dialogRef.close();
    })
  }
  
  ngOnInit(): void {
    this.backupDateForm = this.fb.group({
      doctor: [this.data.surgeryDocId ? this.data.surgeryDocId : '' ],
      hospital: [this.data.surgeryHospitalId ? this.data.surgeryHospitalId : ''],
      fromDate: [],
      toDate: [],
      appointmentType: ['']
    });
    this.getDoctors();
    this.getHospitals(this.data.surgeryDocId)
    this.initTimeSlotGroup();
    this.backupDateForm.get('doctor').valueChanges.subscribe(resp => {
      this.timeSlotForm.patchValue({
        doctor: resp 
      })
    } )

    this.backupDateForm.get('hospital').valueChanges.subscribe(resp => {
      this.timeSlotForm.patchValue({
        organization: resp 
      })
    } )

    this.mapsApiLoader.load().then(resp => {
      this.getPlaceAutocomplete()
    })


    
  }

  onSelectPreviousDays(event) {
    if(event.value === 'Yes') {
      this.minFromDate = null;
    } else {
      this.minFromDate = new Date();
    }
  }

  initTimeSlotGroup() {
    this.timeSlotForm = this.fb.group({
      doctor: [],
      event: ['F2F Scheduled'],
      latlong: [],
      lead: [this.leadId],
      locality: [''],
      posted: [JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY) || '{}').adminUser.email],
      scheduleDate: [],
      slot: [],
      organization: [],
      slotId: []

    })
  }
}
