export class AppConstants {
    static readonly PHONE_PATTERN = /^[6789]{1}[0-9]{9}$/;
    // tslint:disable-next-line:max-line-length
    static readonly EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static readonly URL_PATTERN = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    static readonly LS_USER_KEY = 'user';
    static readonly LS_SERVICE_KEY = 'services';
    static readonly LS_LEAD_ID_KEY = 'leadId';
    static readonly NUMERIC_LEAD_ID_KEY = 'numeric_leadId';

    static readonly ROLLBAR_TOKEN_KEY = 'acfd910bb0f94f5e9ae246e16bcc58ef';
    static readonly UID_PATTERN = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    static readonly APPOINTMENT_FILTER_TIME = ['06:00:00', '06:30:00', '07:00:00', '07:30:00', '08:00:00',
     '08:30:00', '09:00:00', '09:30:00', '10:00:00', '10:30:00',
     '11:00:00', '11:30:00', '12:00:00', '12:30:00', '13:00:00', '13:30:00', '14:00:00', '14:30:00', '15:00:00', '15:30:00', '16:00:00', '16:30:00', '17:00:00', '17:30:00', '18:00:00', '18:30:00', '19:00:00', '19:30:00', '20:00:00'];
     static readonly LS_PERMISSION_KEY = 'permission'

}
