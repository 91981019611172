import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DoctorFacadeService } from '../../facades/doctor-facade.service';
import { Doctor } from '../../models/doctor.model';

@Component({
  selector: 'mpms-doctor-dropdown',
  templateUrl: './doctor-dropdown.component.html',
  styleUrls: ['./doctor-dropdown.component.scss']
})
export class DoctorDropdownComponent implements OnInit, OnChanges {
  doctors: Doctor[];
  @Input() selectedCity: string;
  @Output() emitSelectedDoctor = new EventEmitter();

  constructor(
    private readonly doctorService: DoctorFacadeService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.selectedCity) {
      this.getDoctors();

    }
  }

  getSelectedDoctor(doctor): void {
    this.emitSelectedDoctor.emit(doctor);
  }

  getDoctors(): void {
    this.doctorService.getDoctors(this.selectedCity).subscribe(resp => {
        this.doctors = resp.docList;
        this.doctorService.setHospitals(resp.hospList);
    });
  }

}
