
import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { PutAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';

export class CabCostSave<T> extends PutAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/lead/cab-details`);
    }
}