import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ServicesAdapter } from 'src/app/shared/adapters/service.adapter.service';
import { GetServicesCommand } from 'src/app/shared/commands/get-services.command';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { DoctorFacadeService } from 'src/app/shared/facades/doctor-facade.service';
import { Services } from 'src/app/shared/models/services.model';
import { AnyAdapter } from '../../api/adapters/any-adapter.service';
import { ApiService } from '../../api/api.service';
import { ForgotPasswordCommand } from '../commands/forgot-password.command';
import { GetUserCommand } from '../commands/get-user.command';
import { LoginCommand } from '../commands/login.command';
import { ForgotPassword } from '../models/forgot-password.model';
import { Login } from '../models/login.model';


@Injectable()
export class AuthFacadeService {
    private $permissions = new BehaviorSubject([""]);
    permissionObserable  = this.$permissions.asObservable();
    constructor(
        private readonly apiService: ApiService,
        private readonly anyAdapter: AnyAdapter,
        private readonly serviceAdapter: ServicesAdapter,
        private readonly cookieService: CookieService
    ) {}

    public login(data: any): Observable<any> {
        const command: LoginCommand<any> = new LoginCommand (this.apiService, this.anyAdapter);
        command.parameters = {
            data
        };
        command.showLinearLoader = true;
        return command.execute();
    }

    onForgotPassword(data: ForgotPassword): Observable<any> {
        const command: ForgotPasswordCommand<ForgotPassword> = new ForgotPasswordCommand(this.apiService, this.anyAdapter);
        command.parameters = {
            data
        };
        return command.execute();
    }

    getAuthToken(): string {
        // const user = localStorage.getItem(AppConstants.LS_USER_KEY);

       var authTokenSelect =JSON.parse(localStorage.getItem(AppConstants.LS_USER_KEY) || '{}')
    .adminUser?.authToken;
        var sendToken=""
        let token =localStorage.getItem('cookieToken');
        if(token ==null ||  token==undefined){
            sendToken=authTokenSelect
        }else{
            sendToken=token
        }
      
        return sendToken;
    }

    getUID(): string {
        let result = '';
        const characters = AppConstants.UID_PATTERN;
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = new Date().getTime() + result;
        return result;
    }

    getServices(): Observable<any> {
        const command = new GetServicesCommand(this.apiService, this.serviceAdapter);
        return command.execute();
      }

    clearLocalStorage() {

    }

    getUserDataFromToken(token) {
        const command = new GetUserCommand(this.apiService,this.anyAdapter, token);
        return command.execute();
    }

    emitPermissions(permission) {
        this.$permissions.next(permission);
    }

    getPermission(userData) {
        this.emitPermissions(userData.map(item => item.permissionList).flat().map(a => a.permissionTag));
    }

    storePermission(permission) {
        localStorage.setItem(AppConstants.LS_PERMISSION_KEY, permission.map(item => item.permissionList).flat().map(a => a.permissionTag))
    }

    storeDataToStorage(response, param) {
        if(param === 'lead') {
            this.getPermission(response.permissionGroupList);
        } else {
            this.storePermission(response.permissionGroupList);
        }
          this.storeStaticData();
          localStorage.setItem(AppConstants.LS_USER_KEY, JSON.stringify(response));
    }
    // storeDataToStoragev2(response, param) {
    //     if(param === 'lead') {
    //         this.getPermission(response.permissionGroupList);
    //     } else {
    //         this.storePermission(response.permissionGroupList);
    //     }
    //       this.storeStaticData();
    //       localStorage.setItem(AppConstants.LS_USER_KEY, JSON.stringify(response));

    // }

    storeStaticData(): void {
        this.getServices().subscribe(services => {
          if (services.length > 0) {
            localStorage.setItem(AppConstants.LS_SERVICE_KEY, JSON.stringify(services));
          }
        });
      }
}
