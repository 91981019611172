import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthFacadeService } from "../auth/facades/auth-facade.service";
import { CookieService } from 'ngx-cookie-service';
import { HttpStatusCode } from "../enums/http-status-codes.enum";


@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private readonly auth: AuthFacadeService,
    private readonly router: Router, private readonly cookieService: CookieService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.getAuthToken()) {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
        replaceUrl: true
      });
      return false;
    }


    return true;
  }
}