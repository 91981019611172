import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class ApiService {
  constructor(private readonly http: HttpClient, private readonly  ngxLoader: NgxUiLoaderService,private readonly cookieService: CookieService) {}

  public post(
    url: string,
    payload: any | null,
    options?: object,
    showLinearLoader = false
  ): Observable<any> {
    if (showLinearLoader) {
      this.ngxLoader.start();
    }
    return this.http.post(`${url}`, payload, options).pipe(
      map((resp) => {
        this.hideLoader(showLinearLoader);
        console.log("-------------post----------+++++++++==")
        console.log(resp["statusCode"]==403)
        if(resp["statusCode"]==403){
         
          
          this.cookieService.delete("crossDomainCookie","/");
          localStorage.removeItem('user');
          localStorage.removeItem('permissions');
          localStorage.removeItem('cookieToken')
          // location.reload()
          ;
          // window.location.href = "/login"
            window.location.href="https://admin.medfin.in"
        }
        return resp;
      }),
      catchError((err) => {

        this.hideLoader(showLinearLoader);
     
        throw err;
      })
    );
  }

  public get(url: string, options?: object, showLinearLoader = false): Observable<any> {
    if (showLinearLoader) {
      this.ngxLoader.start();
    }
    return this.http.get(`${url}`, options).pipe(map(res => {
      console.log("--------------------get---+++++++++==")
     
      if(res["statusCode"]==403){
       
          
        this.cookieService.delete("crossDomainCookie","/");
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('cookieToken')
        // location.reload()

        ;
        // window.location.href = "/login"
          window.location.href="https://admin.medfin.in"
      }
      this.hideLoader(showLinearLoader);
      return res;
    }), catchError((err, caught) => {

      this.hideLoader(showLinearLoader);
      throw err;
    }));
  }

  public patch(
    url: string,
    payload: any | null,
    options?: object,
    showLinearLoader = false
  ): Observable<any> {
    if (showLinearLoader) {
      this.ngxLoader.start();
    }
    return this.http.patch(`${url}`, payload, options).pipe(map(res => {
      this.hideLoader(showLinearLoader);
      if(res["statusCode"]==403){
       
          
        this.cookieService.delete("crossDomainCookie","/");
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('cookieToken')
        // location.reload()
        ;
        // window.location.href = "/login"
          window.location.href="https://admin.medfin.in"
      }
      return res;
    }), catchError((err, caught) => {
      this.hideLoader(showLinearLoader);
      throw err;
    }));
  }

  public put(
    url: string,
    payload: any | null,
    options?: object,
    showLinearLoader = false
  ): Observable<any> {
    if (showLinearLoader) {
      this.ngxLoader.start();
    }
    return this.http.put(`${url}`, payload, options).pipe(map(res => {
      if(res["statusCode"]==403){
       
          
        this.cookieService.delete("crossDomainCookie","/");
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('cookieToken')
        // location.reload()
        ;
        // window.location.href = "/login"
          window.location.href="https://admin.medfin.in"
      }
      this.hideLoader(showLinearLoader);
      return res;
    }), catchError((err, caught) => {
      this.hideLoader(showLinearLoader);
      throw err;
    }));
  }

  public delete(url: string, options?: object, showLinearLoader = false): Observable<any> {
    if (showLinearLoader) {
      this.ngxLoader.start();
    }
    return this.http.delete(`${url}`, options).pipe(map(response => {
      if(response["statusCode"]==403){
       
          
        this.cookieService.delete("crossDomainCookie","/");
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('cookieToken')
        // location.reload()
        ;
        // window.location.href = "/login"
          window.location.href="https://admin.medfin.in"
      }
      this.hideLoader(showLinearLoader);
      return response;
    }), catchError((err, caught) => {
      this.hideLoader(showLinearLoader);
      throw err;
    }));
  }

  private hideLoader(showLinearLoader): void {
    if (showLinearLoader){
      this.ngxLoader.stop();
    }
  }


}
