import { IAdapter } from 'src/app/core/api/adapters/i-adapter';
import { ApiService } from 'src/app/core/api/api.service';
import { GetAPICommand } from 'src/app/core/api/commands';
import { environment } from 'src/environments/environment';

export class GetSlotByDateCommand<T> extends GetAPICommand<T> {
    constructor(apiService: ApiService, anyAdapter: IAdapter<T>, docId: string,hosId: string,date:string) {
        super(apiService, anyAdapter , `${environment.apiEndpoint}/calendar/doctor/slot/v3?doctor=${docId}&hospital=${hosId}&date=${date}`);
    }
}
