import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthFacadeService } from './facades/auth-facade.service';
import { Adapters } from './adapters';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [AuthFacadeService, ...Adapters]
})
export class AuthModule { }
