import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LoginComponent } from './login.component';
import { OtpComponent } from '../otp/otp.component';



export const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    }
];

@NgModule({
    imports: [SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)],
    declarations: [LoginComponent, ForgotPasswordComponent ,OtpComponent]
})

export class LoginModule {}
